import React, {useCallback, useEffect, useState} from "react";
import {Badge, Table, Tag, Spin, Row, Col, Space, Button, App, Result, Timeline, Drawer, Alert, DatePicker, Card} from "antd";
import {ApiPost} from "../../../Supports/ApiManager";
import {PaymentColors, PaymentStatusColor, showDatePass, validateEmail} from "../../../Helper";
import dayjs from "dayjs";
import {
    CalendarOutlined,
    FieldTimeOutlined,
    CheckCircleOutlined,
    CopyOutlined,
    UserOutlined,
    QuestionCircleOutlined,
    SaveOutlined
} from '@ant-design/icons';
import {useRecoilState} from "recoil";
import {SocketMerchantReceiveTransactionUpdate} from "../../../Storage/SocketMerchantReceiveTransactionUpdate";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {isMobile} from "react-device-detect";
import {GetColumnSearchProps} from "../../../Supports/GetColumnSearchProps";
import {SocketLiveTransactionsState} from "../../../Storage/SocketLiveTransactions";


const DailyFinance = ({ height, startAt = null, finishAt = null }) => {

    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);
    const [GetSocketLiveTransactionsState,SetSocketLiveTransactionsState]   = useRecoilState(SocketLiveTransactionsState);

    const { message, notification, modal }      = App.useApp();

    const [getLoading,setLoading]               = useState(false);
    const [getRecords,setRecords]               = useState([]);
    const [getActionBtnID,setActionBtnID]       = useState(null);
    const [getTimelapseDrawer,setTimelapseDrawer]       = useState(false);
    const [getTimelapseData,setTimelapseData]       = useState([]);
    const [getTimelapseID,setTimelapseID]       = useState(null);
    const [getMethods,setMethods]               = useState([]);
    const [getMerchants,setMerchants]           = useState([]);
    const [getOperators,setOperators]           = useState([]);
    const [getStatus,setStatus]                 = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    
    const [getDay, setDay] = useState([dayjs(startAt ?? new Date()), dayjs(finishAt ?? new Date())]);

    const copyContent = async (content) => {

        try {
            await navigator.clipboard.writeText(content);
            message.destroy();
            message.success(`(${content}) Kopyalandı`);
        } catch (err) {
            message.error("Kopyalama Hatası");
        }

    }



    useEffect(()=>{

        ApiPost("/merchant/payment/method")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMethods(response.result);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })



        ApiPost("/merchant/payment/merchants")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMerchants(response.result);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })



        ApiPost("/merchant/payment/operators")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setOperators(response.result);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })



        ApiPost("/merchant/payment/status")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setStatus(response.result);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })

    },[])


    const columns = useCallback([
        {
            title: 'Ödeme ID',
            dataIndex: 'order_number',
            align: 'center',
            width: 130,
            ...GetColumnSearchProps("order_number"),
            render: (_,{order_number}) => {
                return (
                    <Space style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-around"}}  onClick={() => copyContent(order_number)}>
                        {order_number}
                    </Space>
                )
            },
        },

        {
            title: 'Personel',
            dataIndex: 'agent',
            align: 'center',
            onFilter: (value, {agent}) => agent?.id?.includes(value) || false,
            render: (_,{agent}) => agent !== null ? `${agent?.name} ${agent?.surname}` : <Tag color={""}style={{display:"flex", flexDirection:"row", alignItems:"center",justifyContent:"center", margin:"0", border:"0", background:"none", color:"#b7b7b7"}}><Spin  size="small" style={{paddingRight:"10px", paddingLeft:"5px", paddingTop:"5px", paddingBottom:"5px"}}/>Bekleniyor..</Tag>
        },
        {
            title: 'Kart Bilgisi',
            dataIndex: 'table_view',
            align: 'left',
            color: '#000',
            render: (_,{table_view}) =>    table_view.map(item => {
                if(item.label === "Banka" && ![null,""," "].includes(item.value)){
                    return (<img src={`/banks/${window.trToEn(item.value.replace(" ","")).replace(/\s/g, '')}.svg`} style={{width:100, height:"fit-content"}}/>)
                }else{
                    return (<Tag color={"#86868600"} style={{color:"#000"}}>{`${item.value}`}</Tag>)
                }
            })
        },
        {
            title: 'Method',
            dataIndex: 'method',
            filterMode: 'tree',
            align: 'center',
            onFilter: (value, {method}) => method?.value?.includes(value) || false,
            render: (_,{method}) => method.label
        },

        {
            title: 'Tutar',
            dataIndex: 'amount',
            sorter:(a,b) => a.amount - b.amount,
            align: 'center',
            render: (_,{amount,currency}) => {
                const formatter = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: currency.value
                });
                return <Space className={"spaceTag"} style={{display:"flex"}}>
                    <Tag color={PaymentColors[currency.value] ?? "#000000"} style={{display:"flex", width:"100%", margin:"0", justifyContent:"center"}}>{formatter.format(amount)}</Tag>
                </Space>;
            }
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            align: 'center',
            width: 100,
            onFilter: (value, {status}) => status?.value?.includes(value) || false,
            render: (_,{status}) => {

                return <Space className={"spaceTag"} style={{display:"flex"}}>
                    <Tag color={PaymentStatusColor[status.value] ?? "#868686"} style={{display:"flex", width:"100%", margin:"0", justifyContent:"center"}} >{status.label}</Tag>
                </Space>;
            }
        },

        {
            title: 'İşlem Tarihi',
            dataIndex: 'creation_date',
            sorter:(a,b) => dayjs(a.creation_date).unix() - dayjs(b.creation_date).unix(),
            render:(_,{creation_date}) => dayjs(creation_date).format("DD/MM/YYYY HH:mm:ss"),
            defaultSortOrder: 'descend',
        },
    ],[getActionBtnID,getMerchants,getMethods, getStatus,GetSocketLiveTransactionsState,getTimelapseID]);



    useEffect(()=>{

        if(getTimelapseID){

            ApiPost(`/payment/history/timelapse`,{
                transaction_id: getTimelapseID
            })
                .then(response => {

                    if(response.status !== "success"){
                        throw new Error(response.message);
                    }

                    setTimelapseDrawer(true);
                    setTimelapseData(response.result);



                })
                .catch(error => {
                    message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                })
        }

    },[getTimelapseID,getSocketReceive]);


    useEffect(()=>{

        setLoading(true);
        ApiPost(`/payment/report/daily?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`,{
            start_at: getDay[0].format("YYYY-MM-DD HH:mm"),
            finish_at: getDay[1].format("YYYY-MM-DD HH:mm")
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: response.result.total
                    },
                });


                setRecords([]);

                if(response?.result?.data !== null){
                    response.result.data.map(item => setRecords(getRecords => [...getRecords,item]));
                }
                setLoading(false);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoading(false);
            })

    },[getSocketReceive,getDay,tableParams?.pagination?.current,tableParams?.pagination?.pageSize])

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setRecords([]);
        }
    };

    return(<>
        <>

        <Space style={{marginBottom:10}}>
                <Button
                    type={getDay[0].format("YYYY-MM-DD") === dayjs(new Date()).format("YYYY-MM-DD") ? "primary" : "default"}
                    onClick={() => {

                        const todayStart = new Date();
                        todayStart.setHours(0);
                        todayStart.setMinutes(0);

                        const todayFinish = new Date();
                        todayFinish.setHours(23);
                        todayFinish.setMinutes(59);

                        setDay([dayjs(todayStart),dayjs(todayFinish)])
                    }}
                    icon={<CalendarOutlined />}>
                    Bugün
                </Button>
                <DatePicker.RangePicker
                    disabledDate={(current) => {
                        return current && current > dayjs().endOf('day');
                    }}

                    onChange={(date, dateString) => {
                        if(dateString[0] === "" || dateString[1] === ""){
                            setDay([dayjs(new Date()),dayjs(new Date())])
                        }else{
                            setDay([date[0],date[1]])
                        }
                    }}
                    defaultValue={getDay}
                    value={getDay}
                    style={{width:350}}
                    showTime={true}
                    format={"DD/MM/YYYY HH:mm"} />
            </Space>
            {
                !isMobile &&
                <Table
                    onChange={handleTableChange}
                    pagination={tableParams.pagination}
                    rowClassName={"custom-row-hover"}
                    rowSelectedBg={"#000000"}
                    rowHoverBg={"#000000"}
                    style={{ width:"100%", marginTop:5}}
                    scroll={{  x:1000}}
                    size={"small"}
                    columns={columns}
                    dataSource={getRecords}
                    bordered
                    rowKey={"id"}
                />
            }

            {
                isMobile && <div style={{ width:"100%", height:"calc(100vh - 150px)", overflow:"scroll" }}>


                    {
                        getRecords.length > 0 && getRecords.map(item => {


                            return(
                                <div style={{marginBottom: "10px", border: "1px solid #c4b8b8", padding: "6px", borderRadius: "6px", paddingTop: "0px", paddingBottom: "0px"}}>
                                    {
                                        columns.map(colItem =>
                                            <div style={{width:"100%", display:"flex", margin:"6px 0px", background:"#f3f3f3", borderRadius:6 }}>
                                                <div   style={{padding: "3px 15px", width:140, display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                    {colItem["title"]}
                                                </div>
                                                <div   style={{padding: "3px 15px", flex: "auto", display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                    {colItem["render"](item,item)}
                                                </div>
                                            </div>)
                                    }
                                </div>
                            )
                        })
                    }


                    {
                        getRecords.length === 0 && <>


                            <Result
                                status="warning"
                                title="Transaction not found"
                            />

                        </>
                    }


                </div>
            }
        </>

        <Drawer width={650} title="İşlem Geçmişi" onClose={() => {
            setTimelapseData([]);
            setTimelapseDrawer(false);
            setTimelapseID(false);
        }} open={getTimelapseDrawer}>

            <Timeline
                items={
                    getTimelapseData.map(item => ({
                        color:  (item.navigate === "user" ? "green" : "red"),
                        children: `${item.message}  / ${dayjs(item.creation_date).format("DD/MM/YYYY HH:mm")}`,
                        dot: item.navigate === "user" ? <CheckCircleOutlined style={{color:"green"}} /> : <UserOutlined style={{color:"red"}} />
                    })
                )}
            />
        </Drawer>

    </>)

};


export default DailyFinance;