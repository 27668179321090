import React, {forwardRef, useEffect, useState} from "react";
import {Descriptions, Tag, Space, App, Drawer, Col, Row, Image, Alert, Form, Button, Modal, Input, Upload} from "antd";
 import {ApiPost, ApiPostFile} from "../../../Supports/ApiManager";
import {
    CheckCircleOutlined,
    CopyOutlined,
    NumberOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import {PaymentColors, showDatePass, validateEmail} from "../../../Helper";
import dayjs from "dayjs";
import {useRecoilState} from "recoil";
import {SocketMerchantReceiveTransactionUpdate} from "../../../Storage/SocketMerchantReceiveTransactionUpdate";
import {isMobile} from 'react-device-detect';
import {SocketLiveTransactionsState} from "../../../Storage/SocketLiveTransactions";
import Settings from "../../../Settings";


const currencyTypeView = false;
const isDetails = false;

const ShowTimePass = ({creation_date}) => {

    const [getShow,setShow] = useState({
        saat:0,
        dakika:0,
        saniye:0
    });

    setInterval(()=>{

        const getCalDate = showDatePass(dayjs(creation_date).format("YYYY-MM-DD HH:mm:ss"));

        setShow(getCalDate);

    },1000)

    if(getShow.saat > 0){
        return `${getShow.saat} Saat ${getShow.dakika} Dakika ${getShow.saniye} Saniye`
    }else{
        if(getShow.dakika === 0){
            return `${getShow.saniye} Saniye`;
        }
        if(getShow.dakika > 0){
            return `${getShow.dakika} Dakika ${getShow.saniye} Saniye`
        }
    }
}


const getIcons =  {
    bank_account: "bank_account.png",
    birthday: "birthday.png",
    credit_card_pin: "credit_card_pin.png",
    credit_card: "credit_card.png",
    father_name: "father_name.png",
    gsm_number: "gsm_number.png",
    mobile_bank_username: "mobile_bank_username.png",
    mobile_verification: "mobile_verification.png",
    mother_name: "mother_name.png",
    mother_surname: "mother_surname.png",
    sms_code: "sms_code.png",
    sms_verification: "sms_verification.png",
    payment_success: "payment_success.png",
    payment_failed: "payment_failed.png",
    waiting_3d_pin_request: "sms_code.png",
    card_info_request: "credit_card.png",
};


const PaymentDetails = ({transaction,onClose}) => {

    const [getPriceForm]                                                    = Form.useForm();
    const { message, notification, modal }                                  = App.useApp();
    const [GetSocketLiveTransactionsState,SetSocketLiveTransactionsState]   = useRecoilState(SocketLiveTransactionsState);
    const [getSocketReceive,setSocketReceive]                               = useRecoilState(SocketMerchantReceiveTransactionUpdate);

    const [getLoading, setLoading]                                          = useState(false);
    const [getLoadingButtons, setLoadingButtons]                            = useState(false);
    const [getViewDataState, setViewDataState]                                  = useState({});
    const [getApiPaymentHeader, setApiPaymentHeader]                        = useState([]);
    const [getApiPaymentDetails, setApiPaymentDetails]                      = useState([]);
    const [getApiPaymentStatus, setApiPaymentStatus]                        = useState([]);
    const [getCustomerStatus, setCustomerStatus]                         = useState(false);
    const [isOnline, setIsOnline]                                        = useState(navigator.onLine);
    const [getUploadInit, setUploadInit]                                 = useState(false);


    const uploadProps = {
        multiple: false,
        customRequest({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) {
            ApiPostFile(`/payment/${transaction.transaction_id}/slip/upload`,{
                file
            },(percent)=> {
                onProgress({ percent:percent }, file);
            })
                .then(response => {
                    message.success("Dekont Yüklendi");
                    onSuccess(response, file);
                })
                .catch(error => {
                    message.error("Dekont Yükleme Başarısız");
                    onError(error);
                });
            return {
                abort() {
                    message.error("Dekont Yükleme Başarısız");
                },
            };
        },
    };


    const handleStatusChange = () => {
        setIsOnline(navigator.onLine);
        if(navigator.onLine){
            getViewData();
        }else{
            console.log("Network connection failed");
        }
    };


    useEffect(() => {

        setCustomerStatus(getViewDataState?.transaction_id !== undefined ? GetSocketLiveTransactionsState.includes(getViewDataState?.transaction_id) : false);

    }, [GetSocketLiveTransactionsState]);

    useEffect(() => {

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    useEffect(() => {

        window.addEventListener("focus", handleStatusChange);
        return () => {
            window.removeEventListener("focus", handleStatusChange);
        };
    }, []);

    const GetNewPriceButton =  (props) => {


        const updatePriceCallback = (values) => {

            message.loading("İşlem yapılıyor");
            ApiPost(`/payment/${props.id}/price/update`,{
                amount: parseFloat(values.amount)
            })
                .then((responseStatus) => {

                    if(responseStatus.status !== "success"){
                        throw new Error(responseStatus.message);
                    }

                    message.destroy();
                    message.success("İşlem başarılı");
                    Modal.destroyAll();

                    getViewData();

                })
                .catch(error => {
                    message.destroy();
                    message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                })

        };

        return(

            <Form
                form={getPriceForm}
                onFinish={updatePriceCallback}
                onFinishFailed={() => {}}
                autoComplete="off"
            >
                <div>
                    <div style={{marginTop:15}}>
                        {props.old}
                    </div>


                    <Form.Item name="amount" rules={[{ required: true, message:"Fiyat giriniz" }]}>
                        <Input
                            prefix={<NumberOutlined />}
                            inputMode={"numeric"}
                            className={"questionInput"}
                            style={{ marginTop:15, marginBottom:15}}
                            block
                            placeholder={"Yeni tutar giriniz..."}
                            suffix={
                                <Button
                                    onClick={() => getPriceForm.submit()}
                                    type="primary"
                                    icon={<CheckCircleOutlined />} >
                                    Güncelle
                                </Button>
                            }
                        />
                    </Form.Item>
                </div>
            </Form>
        )
    };

    const PriceFormatter = ({nochange, id, amount, currency}) => {

        const formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: currency
        });

        return (
            <Space >
                <div>
                    {currencyTypeView && <Tag color={PaymentColors[currency] ?? "#000000"} style={{border: "1px dashed purple", fontSize:20}}>{currency}</Tag> }
                    <Tag color={PaymentColors[currency] ?? "var(--corpPrimary)"} style={{ fontSize:20, color:"#000", fontWeight:"600"}}>{formatter.format(amount)}</Tag>
                </div>


            </Space>
        )

    }


    const copyContent = async (content) => {

        try {
            await navigator.clipboard.writeText(content);
            message.destroy();
            message.success(`(${content}) Kopyalandı`);
        } catch (err) {
            message.error("Kopyalama Hatası");
        }

    }


    const getViewData = () =>  {

        setLoading(true);
        ApiPost(`/payment/${transaction.transaction_id}/view`)
            .then((response) => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }


                if(["payment_failed","payment_success"].includes(response.result.payment_request.status.value)){
                    onClose(true);
                }

                setLoading(false);


                setViewDataState(response.result);


                if(isDetails){
                    setApiPaymentHeader(
                        [
                            {
                                key: Math.random().toString(),
                                label: 'Bayi',
                                span:3,
                                children: response.result.merchant.title,
                            },
                            {
                                key: Math.random().toString(),
                                label: 'Kayıt Kodu',
                                span:3,
                                children: response.result.transaction_id,
                            },
                            {
                                key: Math.random().toString(),
                                label: 'İşlem Kodu',
                                span:3,
                                children: response.result.payment_request.order_number,
                            },

                            {
                                key: Math.random().toString(),
                                label: 'Geçen Süre',
                                span:3,
                                children: <ShowTimePass creation_date={response.result.creation_date} />,
                            },
                            {
                                key: Math.random().toString(),
                                label: 'Ödeme Methodu',
                                span:3,
                                children:  <Space>{response.result.payment_request.method.label} <div style={{width:15, height:15, borderRadius:100, background: (response.result.payment_request.method.value.includes("WITHDRAW")) ? "red" : "green" }}></div> </Space>
                            },
                            {
                                key: Math.random().toString(),
                                label: 'Ödeme Para Cinsi',
                                span:3,
                                children: response.result.payment_request.currency.label,
                            },
                            {
                                key: Math.random().toString(),
                                label: 'Ödeme Tutarı',
                                span:3,
                                children: <PriceFormatter id={response.result.transaction_id} amount={response.result.payment_request.amount} currency={response.result.payment_request.currency.value} />,
                            }
                        ]
                    );

                    setApiPaymentDetails([

                        {
                            key: Math.random().toString(),
                            label: 'Kullanı Adı',
                            span:3,
                            children: (
                                <>
                                    {
                                        (response.result.payment_request.client_username) !== null &&
                                        <Row>
                                            <Col span={20} style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}><Tag color={"blue"} style={{border: "1px dashed purple"}}>{response.result.payment_request.client_username}</Tag></Col>
                                            <Col span={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Button type={"primary"} shape={"circle"} onClick={() => copyContent(response.result.payment_request.client_username)} icon={<CopyOutlined />} /></Col>
                                        </Row>

                                    }
                                    {
                                        (response.result.payment_request.client_username) === null &&
                                        <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                                    }
                                </>
                            ) ,
                        },
                        {
                            key: Math.random().toString(),
                            label: 'Kullanı E-Posta',
                            span:3,
                            children: (
                                <>
                                    {
                                        validateEmail(response.result.payment_request.client_email) &&
                                        <Tag color={"blue"} style={{border: "1px dashed purple"}}>{response.result.payment_request.client_email}</Tag>
                                    }
                                    {
                                        !validateEmail(response.result.payment_request.client_email) &&
                                        <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                                    }
                                </>
                            ) ,
                        },
                        {
                            key: Math.random().toString(),
                            label: 'Kullanıcı Açıklama',
                            span:3,
                            children: (
                                <>
                                    {
                                        (response.result.payment_request.client_description) !== null &&
                                        <Tag color={"blue"} style={{border: "1px dashed purple"}}>{response.result.payment_request.client_description}</Tag>
                                    }
                                    {
                                        (response.result.payment_request.client_description) === null &&
                                        <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                                    }
                                </>
                            ) ,
                        },
                        {
                            key: Math.random().toString(),
                            label: 'Kullanı Kodu',
                            span:3,
                            children: (
                                <>
                                    {
                                        (response.result.payment_request.client_custom)  !== null &&
                                        <Tag color={"blue"} style={{border: "1px dashed purple"}}>{response.result.payment_request.client_custom}</Tag>
                                    }
                                    {
                                        (response.result.payment_request.client_custom) === null &&
                                        <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                                    }
                                </>
                            ) ,
                        },
                    ]);
                }else{
                    setApiPaymentHeader([
                            {
                                key: Math.random().toString(),
                                label: 'Method',
                                span:3,
                                children:  <Space>{response.result.payment_request.method.label} <div style={{width:15, height:15, borderRadius:100, background: (response.result.payment_request.method.value.includes("WITHDRAW")) ? "red" : "green" }}></div> </Space>
                            },
                            {
                                    key: Math.random().toString(),
                                    label: 'Tutar',
                                    span:3,
                                    children: <PriceFormatter id={response.result.transaction_id} amount={response.result.payment_request.amount} currency={response.result.payment_request.currency.value} />,
                            },
                            {
                                key: Math.random().toString(),
                                label: 'Dekont',
                                span:3,
                                children:
                                    <Row>
                                        <Space direction={"horizontal"} align={"center"}>
                                            {
                                                response.result.slip === null ? <Button onClick={() => setUploadInit(true)}>Dekont Yükle</Button> : <Image width={200} src={Settings.cdn + "/" + response.result.slip.slip_serial + "?time=" + response.result.slip.creation_date}/>
                                            }
                                        </Space>
                                    </Row>,
                            },
                        ]
                    );


                    setApiPaymentDetails([

                        {
                            key: Math.random().toString(),
                            label: 'Kullanıcı Adı',
                            span:3,
                            children: (
                                <>
                                    {
                                        (response.result.payment_request.client_username) !== null &&
                                        <Row>
                                            <Col span={20} style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                            <Input value={response.result.payment_request.client_username} disabled/>
                                            </Col>
                                            <Col span={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Button type={"primary"} shape={"circle"} onClick={() => copyContent(response.result.payment_request.client_username)} icon={<CopyOutlined />} /></Col>
                                        </Row>

                                    }
                                    {
                                        (response.result.payment_request.client_username) === null &&
                                        <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                                    }
                                </>
                            ) ,
                        }
                    ]);
                }

                Object.keys(response.result.view).map(item => {

                    if(!(response.result[item]?.length <= 1 || response.result[item] === null)){
                        setApiPaymentDetails(currency => [...currency, {
                            key: Math.random().toString(),
                            label: response.result.view[item],
                            span:3,
                            children: <Row>
                                <Col span={20} style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                <div dangerouslySetInnerHTML={{__html:response.result[item]}}/></Col>
                                <Col span={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Button type={"primary"} shape={"circle"} onClick={() => copyContent(response.result[item])} icon={<CopyOutlined />} /></Col>
                            </Row>

                        }]);
                    }
                })


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })


    };

    useEffect(() => {


        if(getViewDataState?.transaction_id !== undefined && getApiPaymentStatus.length === 0){

            setLoadingButtons(true);
            ApiPost(`/merchant/payment/method/status`,{ method: getViewDataState.payment_request.method.value  })
                .then((responseStatus) => {

                    if(responseStatus.status !== "success"){
                        throw new Error(responseStatus.message);
                    }


                    setApiPaymentStatus
                    ([

                        {
                            key: Math.random().toString(),
                            label: 'İşlem',
                            span:3,
                            children:
                                <Row>

                                    {
                                        Object.keys(responseStatus.result).map(item =>
                                            <Col span={isMobile ? 6  : 6} style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:10}}>

                                                { getIcons[item] !== undefined && <img src={`/icons/${getIcons[item]}`} onClick={e => changeStatus(
                                                    <PriceFormatter nochange id={getViewDataState.transaction_id} amount={getViewDataState.payment_request.amount} currency={getViewDataState.payment_request.currency.value} />,
                                                    getViewDataState,
                                                    item,
                                                    transaction, responseStatus.result)}  style={{ cursor:"pointer", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px",  width: "90%"}}  alt={""}/> }

                                                { getIcons[item] === undefined && <div className={"buttonNoImage"} onClick={e => changeStatus(
                                                    <PriceFormatter  nochange id={getViewDataState.transaction_id} amount={getViewDataState.payment_request.amount} currency={getViewDataState.payment_request.currency.value} />,
                                                    getViewDataState,
                                                    item,
                                                    transaction,
                                                    responseStatus.result
                                                )} style={{cursor:"pointer",  background: [ "var(--tableHeadBackground)",  ], color:"#FFFFFF",  display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px", height: "-webkit-fill-available", width: "90%"}}>  {responseStatus.result[item]} </div> }

                                            </Col> )
                                    }
                                </Row>
                        },
                    ]);

                    setLoadingButtons(false);

                })
                .catch(error => {
                    message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                })

        }
    },[getViewDataState])


    const changeStatus = (view,detailsAll,key,transaction, statuses = {}) => {

        if(["payment_success","payment_failed"].includes(key)){
            modal.confirm({
                title: `Bu İşlemini "${statuses[key]}" olarak onaylıyor musunuz`,
                content: view,
                okText: 'Evet',
                cancelText: 'Hayır',
                onOk(){

                    message.loading("İşlem yapılıyor");

                    ApiPost(`/payment/${transaction.transaction_id}/status/update`,{
                        status: key
                    })
                        .then((responseStatus) => {

                            if(responseStatus.status !== "success"){
                                throw new Error(responseStatus.message);
                            }

                            getViewData();

                            message.destroy();
                            message.success("İşlem başarılı");

                        })
                        .catch(error => {
                            message.destroy();
                            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                        })

                }
            });

        }else{

            message.loading("İşlem yapılıyor");

            ApiPost(`/payment/${transaction.transaction_id}/status/update`,{
                status: key
            })
                .then((responseStatus) => {

                    if(responseStatus.status !== "success"){
                        throw new Error(responseStatus.message);
                    }

                    getViewData();
                    message.destroy();
                    message.success("İşlem başarılı");

                })
                .catch(error => {
                    message.destroy();
                    message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                })
        }

    };


    useEffect(getViewData,[getSocketReceive]);




    return(<>
        <>
            <Descriptions key={`dbsla_1_${transaction.order_number}`} labelStyle={{ width:100, fontSize:12,  padding: "3px 8px"  }} size={"small"} bordered items={getApiPaymentHeader} />
            <Descriptions key={`dbsla_2_${transaction.order_number}`} labelStyle={{ width:100, fontSize:12, padding: "3px 8px"}} size={"small"} bordered items={getApiPaymentDetails}  style={{marginTop:20}}/>
        </>

        <>
            <Descriptions key={`dbsla_3_${transaction.order_number}`} labelStyle={{ width:100,  fontSize:12, padding: "3px 8px" }} layout="vertical" size={"small"} bordered items={getApiPaymentStatus}  style={{marginTop:20}}/>
        </>

        <Drawer title="Slip Yükleme" onClose={() => { setUploadInit(false) }} open={getUploadInit}>
            <Upload {...uploadProps}>
                <Button type={"primary"} shape={"round"} block >Dekont Seçiniz</Button>
            </Upload>
        </Drawer>


    </>)

};


export default PaymentDetails;

