import React, {useCallback, useEffect, useState} from "react";
import {Badge, Table, Tag, Row, Col, Space, Button, App, Result, Input, Modal, Tooltip} from "antd";
import {ApiPost} from "../../../Supports/ApiManager";
import {PaymentColors, PaymentStatusColor, showDatePass, validateEmail} from "../../../Helper";
import dayjs from "dayjs";
import {CheckCircleOutlined, CopyOutlined, QuestionCircleOutlined, SaveOutlined,InfoCircleOutlined} from '@ant-design/icons';
import {isMobile} from "react-device-detect";
import {GetColumnSearchProps} from "../../../Supports/GetColumnSearchProps";

function isEmpty(obj) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

const DailyFinanceWithFilter = ({filtersSearch}) => {

    const { message, notification, modal }      = App.useApp();

    const [getLoading,setLoading]               = useState(false);
    const [getRecords,setRecords]               = useState([]);
    const [getActionBtnID,setActionBtnID]       = useState(null);
    const [getMethods,setMethods]               = useState([]);
    const [getMerchants,setMerchants]           = useState([]);
    const [getStatus,setStatus]                 = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setRecords([]);
        }
    };

    const copyContent = async (content) => {

        try {
            await navigator.clipboard.writeText(content);
            message.success(`(${content}) Kopyalandı`);
        } catch (err) {
            message.error("Kopyalama Hatası");
        }

    }

    useEffect(()=>{

        ApiPost("/merchant/payment/method")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMethods(response.result);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })



        ApiPost("/merchant/payment/merchants")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMerchants(response.result);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })



        ApiPost("/merchant/payment/status")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setStatus(response.result);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })

    },[])


    const columns = useCallback([
        {
            title: 'Bayi',
            dataIndex: 'merchant',
            align: 'left',
            onFilter: (value, {merchant}) => merchant.id.includes(value),
            render: (_,{merchant}) => merchant?.title
        },
        {
            title: 'Sipariş No',
            dataIndex: 'order_number',
            align: 'left',
            ...GetColumnSearchProps("order_number"),
            render: (_,{order_number}) => {

                return (
                    <Space style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                        {order_number}
                        <Button type={"primary"} shape={"circle"} onClick={() => copyContent(order_number)} icon={<CopyOutlined />} />
                    </Space>
                )
            }
        },
        {
            title: 'Personel',
            dataIndex: 'agent',
            align: 'left',
            render: (_,{agent}) => agent !== null ? `${agent?.name} ${agent?.surname}` : <Tag color={"warning"} style={{color:"black", background:"none", textAlign:"left", padding:"0"}}>Alınmamış</Tag>
        },
        {
            title: 'Kullanıcı',
            dataIndex: 'client_username',
            align: 'left',
            width:100,
            ...GetColumnSearchProps("client_username"),
            render: (_,{client_username}) => client_username
        }, /*
        {
            title: 'Kullanıcı E-Posta',
            dataIndex: 'client_email',
            align: 'left',
            render: (_,{client_email}) => {
                if(validateEmail(client_email)){
                    return <Tag color={"success"}>{client_email}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        }, */
        {
            title: 'Method',
            dataIndex: 'method',
            align: 'left',
            onFilter: (value, {method}) => method.value.includes(value),
            render: (_,{method}) => method.label
        },
        {
            title: 'Tutar',
            dataIndex: 'amount',
            sorter:(a,b) => a.amount - b.amount,
            render: (_,{amount,currency}) => {
                const formatter = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: currency.value
                });
                return <Space className={"spaceTag"} style={{display:"flex"}}>
                    <Tag color={PaymentColors[currency.value] ?? "#000000"} style={{display:"flex", width:"100%", margin:"0", justifyContent:"center"}}>{formatter.format(amount)}</Tag>
                </Space>;
            }
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            onFilter: (value, {status}) => status.value.includes(value),
            render: (_,{status}) => {

                return <Space>
                    <Tag color={PaymentStatusColor[status.value] ?? "#000000"} style={{}}>{status.label}</Tag>
                </Space>;
            }
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'creation_date',
            sorter:(a,b) => dayjs(a.creation_date).unix() - dayjs(b.creation_date).unix(),
            render:(_,{creation_date}) => dayjs(creation_date).format("DD/MM/YYYY HH:mm:ss"),
            defaultSortOrder: 'descend',
        },
    ],[getActionBtnID,getMerchants,getMethods, getStatus]);

    useEffect(()=>{

         if(isEmpty(filtersSearch)){
            return null;
        }

        setLoading(true);
        ApiPost(`/payment/report/daily/system?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`,filtersSearch)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: response.result.total
                    },
                });

                setRecords([]);
                response.result.data.map(item => setRecords(getRecords => [...getRecords,item]));
                setLoading(false);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoading(false)
            })

    },[filtersSearch,tableParams?.pagination?.current,tableParams?.pagination?.pageSize])



    return(<>
        <>
            {
                !isMobile &&
                <Table
                    onChange={handleTableChange}
                    rowClassName={"custom-row-hover"}
                    rowSelectedBg={"#000000"}
                    rowHoverBg={"#000000"}
                    style={{ width:"100%"}}
                    scroll={{  x:1000}}
                    size={"small"}
                    columns={columns}
                    dataSource={getRecords}
                    pagination={tableParams.pagination}
                    bordered
                />
            }

            {
                isMobile && <div style={{ width:"100%", height:"calc(100vh - 150px)", overflow:"scroll" }}>


                    {
                        getRecords.length > 0 && getRecords.map(item => {


                            return(
                                <div style={{marginBottom: "10px", border: "1px solid #c4b8b8", padding: "6px", borderRadius: "6px", paddingTop: "0px", paddingBottom: "0px"}}>
                                    {
                                        columns.map(colItem =>
                                            <div style={{width:"100%", display:"flex", margin:"6px 0px", background:"#f3f3f3", borderRadius:6 }}>
                                                <div   style={{padding: "3px 15px", width:140, display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                    {colItem["title"]}
                                                </div>
                                                <div   style={{padding: "3px 15px", flex: "auto", display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                    {colItem["render"](item,item)}
                                                </div>
                                            </div>)
                                    }
                                </div>
                            )
                        })
                    }


                    {
                        getRecords.length === 0 && <>


                            <Result
                                status="warning"
                                title="İşlem Bulunamadı"
                            />

                        </>
                    }


                </div>
            }
        </>
    </>)

};


export default DailyFinanceWithFilter;