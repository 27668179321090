import React, { useCallback, useEffect, useState } from "react";
import {
    Badge,
    Button,
    Col,
    Row,
    Space,
    Table,
    Tag,
    Drawer,
    Dropdown,
    Descriptions,
    App,
    Card,
    Collapse,
} from "antd";
import {
    PaymentColors,
    PaymentStatusColor,
    showDatePass,
    validateEmail,
} from "../../../Helper";
import dayjs from "dayjs";
import { useRecoilState } from "recoil";
import { SocketMerchantReceiveTransactionStatusUpdate } from "../../../Storage/SocketMerchantReceiveTransactionStatusUpdate";
import {
    ArrowRightOutlined,
    CaretDownOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";
import { ApiPost } from "../../../Supports/ApiManager";
import PaymentDetails from "./PaymentDetails";
import { isMobile } from "react-device-detect";

const OnProcessRoomSingle = ({ height }) => {
    const { message, notification, modal } = App.useApp();

    const [getStatusReceive] = useRecoilState(
        SocketMerchantReceiveTransactionStatusUpdate
    );

    const [getLoading, setLoading] = useState(false);
    const [getRecords, setRecords] = useState([]);
    const [openDetailsTransaction, setDetailsTransaction] = useState({});
    const [openDetails, setOpenDetails] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);


    const handleStatusChange = () => {
        setIsOnline(navigator.onLine);
        if (navigator.onLine) {
            getData();
        } else {
            console.log("Network connection failed");
        }
    };


    useEffect(() => {
        // Listen to the online status
        window.addEventListener("online", handleStatusChange);

        // Listen to the offline status
        window.addEventListener("offline", handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener("online", handleStatusChange);
            window.removeEventListener("offline", handleStatusChange);
        };
    }, [isOnline]);


    useEffect(() => {
        window.addEventListener("focus", handleStatusChange);
        return () => {
            window.removeEventListener("focus", handleStatusChange);
        };
    }, []);

    const closeDetailsFn = () => {
        setOpenDetails(false);
        setDetailsTransaction({});
    };

    const openDetailsFn = (transaction) => {
        setDetailsTransaction(transaction);
        setOpenDetails(true);
    };

    const getData = () => {
        ApiPost("/payment/list/on-process")
            .then(({ status, message, result }) => {
                if (status !== "success") {
                    throw new Error(message);
                }

                setRecords(result);
            })
            .catch((error) => {
                message.error(
                    error?.response?.data?.message === undefined
                        ? error.message
                        : error?.response?.data?.message
                );
                console.error("Error : ", error);
            });
    };

    useEffect(getData, [getStatusReceive]);

    const columns = useCallback(
        [
            {
                title: "Kullanıcı Adı",
                dataIndex: "client_username",
                align: "left",
                width: 100,
                render: (_, { client_username }) => client_username,
            } /*

        {
            title: 'Kullanıcı E-Posta',
            dataIndex: 'client_email',
            align: 'left',
            render: (_,{client_email}) => {
                if(validateEmail(client_email)){
                    return <Tag color={"success"}>{client_email}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        },
        */,
            {
                title: "Method",
                dataIndex: "method",
                align: "left",
                render: (_, { method }) => method.label,
            },
            {
                title: "Kart Bilgisi",
                dataIndex: "table_view",
                align: "left",
                render: (_, { table_view }) =>
                    table_view.map((item) => {
                        if (
                            item.label === "Banka" &&
                            ![null, "", " "].includes(item.value)
                        ) {
                            return (
                                <img
                                    src={`/banks/${window.trToEn(
                                        item.value.replace(" ", "")
                                    )}.svg`}
                                    style={{ width: 100, height: "fit-content" }}
                                />
                            );
                        } else {
                            return (
                                <Tag color={"#86868600"} style={{color:"#000"}}>{`${item.value}`}</Tag>
                            );
                        }
                    }),
            },
            {
                title: "Tutar",
                dataIndex: "amount",
                render: (_, { amount, currency }) => {
                    const formatter = new Intl.NumberFormat("tr-TR", {
                        style: "currency",
                        currency: currency.value,
                    });
                    return (
                        <Space className={"spaceTag"} style={{display:"flex"}}>
                            <Tag
                                color={PaymentColors[currency.value] ?? "#000000"}
                                style={{ display:"flex", width:"100%", margin:"0", justifyContent:"center"}}
                            >
                                {formatter.format(amount)}
                            </Tag>
                        </Space>
                    );
                },
            },
            {
                title: "Durum",
                dataIndex: "status",
                render: (_, { status }) => {
                    return (
                        <Space className={"spaceTag"} style={{display:"flex"}}>
                            <Tag
                                color={PaymentStatusColor[status.value] ?? "black"}
                                style={{ display:"flex", width:"100%", margin:"0", justifyContent:"center"}}
                            >
                                {status.label}
                            </Tag>
                        </Space>
                    );
                },
            },
            {
                title: "Son Güncelleme",
                dataIndex: "updated_date",
                render: (_, { updated_date }) => dayjs(updated_date).format("HH:mm"),
                sorter: (a, b) =>
                    dayjs(a.creation_date).unix() - dayjs(b.creation_date).unix(),
                defaultSortOrder: "descend",
                align: "center",
            },
            {
                title: "İşlemler",
                dataIndex: "transaction_id",
                align: "center",
                render: (_, transaction) => {
                    return (
                        <>
                            <Button
                                block
                                loading={transaction.id === openDetailsTransaction?.id}
                                type="primary"
                                icon={<ArrowRightOutlined />}
                                onClick={() => openDetailsFn(transaction)}
                            >
                                Detaylar
                            </Button>
                        </>
                    );
                },
            },
        ],
        [openDetailsTransaction]
    );

    return (
        <>
            {!isMobile && (
                <Table
                    style={{ width: "100%" }}
                    scroll={{ x: 1000 }}
                    size={"small"}
                    columns={columns}
                    dataSource={getRecords}
                    bordered
                    pagination={false}
                />
            )}

            {isMobile && (
                <Collapse
                    style={{ border: "none" }}
                    activeKey={getRecords.map(
                        (item, index) => `cls_${index}_${item.order_number}`
                    )}
                    collapsible={"disabled"}
                    onChange={(i) => console.log(i)}
                    items={getRecords.map((item, index) => ({
                        key: `cls_${index}_${item.order_number}`,
                        label: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                                key={`cls_ok_dblast_${index}_${item.order_number}`}
                            >
                                {" "}
                                {item.table_view.map((item) =>
                                    item.label === "Banka" &&
                                    ![null, "", " "].includes(item.value) ? (
                                        <img
                                            src={`/banks/${window.trToEn(
                                                item.value.replace(" ", "")
                                            )}.svg`}
                                            style={{ width: 100, height: "fit-content" }}
                                        />
                                    ) : (
                                        <Tag color={"black"}>{`${item.label} : ${item.value}`}</Tag>
                                    )
                                )}{" "}
                                <span style={{ color: "red" }}>
                  {" "}
                                    ({`${index + 1} / ${getRecords.length}`})
                </span>{" "}
                            </div>
                        ),
                        children: (
                            <PaymentDetails
                                key={`cls_ok_dbl_${index}_${item.order_number}`}
                                onClose={closeDetailsFn}
                                transaction={item}
                            />
                        ),
                        style: {
                            marginBottom: 24,
                            border: "1px solid black",
                            borderRadius: 6,
                        },
                    }))}
                    defaultActiveKey={getRecords.map(
                        (item, index) => `cls_${index}_${item.order_number}`
                    )}
                />
            )}

            <Drawer
                title="İşlem Detayı"
                width={650}
                placement="right"
                onClose={closeDetailsFn}
                bodyStyle={{ padding: 20 }}
                open={openDetails}
            >
                {openDetailsTransaction?.id && (
                    <PaymentDetails
                        onClose={closeDetailsFn}
                        transaction={openDetailsTransaction}
                    />
                )}
            </Drawer>
        </>
    );
};

export default OnProcessRoomSingle;
