import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {
    Row,
    Col,
    Card,
    Form,
    DatePicker,
    Button,
    Select,
    Space,
    Badge,
    Tag,
    Table,
    Input,
    App,
    TimePicker,
} from "antd";
import 'dayjs/locale/tr';
import {FilterOutlined, PlusOutlined} from '@ant-design/icons';
import {PasswordInput} from "antd-password-input-strength";
import {ApiPost} from "../../../../../../Supports/ApiManager";
import dayjs from "dayjs";
import {useRecoilState} from "recoil";
import {TimeZonesStorage} from "../../../../../../Storage/TimeZonesStorage";




const PersonalCreateMerchant = (props) => {

    const { message, notification, modal }                      = App.useApp();

    const [PersonalForm]                                        = Form.useForm();
    const [level, setLevel]                                     = useState(0);
    const [getLoading, setLoading]                              = useState(false);
    const [getSubMerchants, setSubMerchants]                    = useState([]);
    const [getTimeZones,setTimeZones]                           = useRecoilState(TimeZonesStorage);


    const onFinish = (values) => {

        setLoading(true);

        ApiPost(`/merchant/${props.merchantID}/team/create`,{
            name:       values.name,
            surname:    values.surname,
            email:      values.email,
            password:   values.password,
            status:     values.status,
            role:       values.role,
            timezone:       values.timezone,
            multiple_tab:       values.multiple_tab,
            "2fa_status":       values["2fa_status"],
            shift_start:        dayjs(values.shift[0]).format("HH:mm:ss"),
            shift_finish:       dayjs(values.shift[1]).format("HH:mm:ss"),
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoading(false);
                message.success(response.message);
                props?.onChange(true);


            }).catch(error =>{
                setLoading(false);
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                props?.onChange(true);
            })


    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return(<>

        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

            <Form
                disabled={getLoading}
                form={PersonalForm}
                name="PersonalForm"
                layout={"vertical"}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Form.Item
                    hasFeedback
                    label="İsim"
                    name="name"
                    rules={[{required: true, message: 'This field is required!',} ]}>
                    <Input placeholder={"İsim giriniz"} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Soyisim"
                    name="surname"
                    rules={[{required: true, message: 'This field is required!',} ]}>
                    <Input placeholder={"Soyisim giriniz"} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="E-Posta"
                    name="email"
                    rules={[{required: true, message: 'This field is required!'}, { type:"email", message: "E-Posta yanlış!"} ]}>
                    <Input placeholder={"E-Posta giriniz"} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Şifre"
                    name="password"
                    rules={[{required: true, message: 'This field is required!'},
                        {
                            validator: async () => {
                                return level >= 1 ? Promise.resolve() : Promise.reject("Şifre güvenliği düşük");
                            },
                            message: "Güçlü bir şifre giriniz"
                        }]}>
                    <PasswordInput onLevelChange={setLevel} />
                </Form.Item>


                <Form.Item
                    hasFeedback
                    label="Durum"
                    name="status"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select placeholder={"Durum seçiniz"} options={[
                        {
                            key: Math.random().toString(),
                            label:"Aktif",
                            value:"active"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Pasif",
                            value:"passive"
                        }
                    ]} />
                </Form.Item>


                <Form.Item
                    hasFeedback
                    label="Kimlik Doğrulama"
                    name="2fa_status"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select placeholder={"Durum seçiniz"} options={[
                        {
                            key: Math.random().toString(),
                            label:"Aktif",
                            value:"active"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Pasif",
                            value:"passive"
                        }
                    ]} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Bölge"
                    name="timezone"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select
                        placeholder={"Seçim yapınız"}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={getTimeZones?.map(item => ({ value: item, label: item }))} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Rol"
                    name="role"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select placeholder={"Yetki seçiniz"} options={[
                        {
                            key: Math.random().toString(),
                            label:"Sistem Yöneticisi",
                            value:"root"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Talep Operatörü",
                            value:"admin"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Finans Müdürü",
                            value:"finance"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Raportör",
                            value:"operator"
                        },
                    ]} />
                </Form.Item>


                <Form.Item
                    hasFeedback
                    label="Çoklu Sekme"
                    name="multiple_tab"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select suffixIcon={<FilterOutlined />} placeholder={"Çoklu Sekme"} options={[
                        {
                            key: Math.random().toString(),
                            label:"Aktif",
                            value:"active"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Pasif",
                            value:"passive"
                        }
                    ]} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Mesai Saatleri"
                    name="shift"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <TimePicker.RangePicker style={{width:"100%"}} />
                </Form.Item>


                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 8,
                    }}>
                    <Button loading={getLoading} icon={<PlusOutlined />} type="primary" htmlType="submit" block>
                        Ouştur
                    </Button>
                </Form.Item>


            </Form>

        </div>
    </>)

};


export default PersonalCreateMerchant;