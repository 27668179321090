export const VisitorChartData = {
    series: [
      {
          name: "Günlük Çekim",
          data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      },
      {
          name: "Günlük Yatırım",
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
      }
  ],
  categories:[
      '01', 
      '02', 
      '03', 
      '04', 
      '05', 
      '06', 
      '07', 
      '08', 
      '09',
      '10', 
      '11', 
      '12'
  ]
}


export const MonthlyChartData = {
  series: [
    {
        name: "Aylık Çekim",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
    },
    {
        name: "Aylık Yatırım",
        data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
    }
],
categories:[
    'Ock', 
    'Şbt', 
    'Mar', 
    'Nis', 
    'May', 
    'Haz', 
    'Tem', 
    'Ağu', 
    'Eyl', 
    'Eki',
    'Kas', 
    'Ara'
]
}

export const AnnualStatisticData = [
  {
      title: 'Revenue',
      value:'$2,454', 
      status: -11.4,
      subtitle: `Compare to last year (2019)`
  },
  {
      title: 'Sales',
      value:'$6,982', 
      status: 8.2,
      subtitle: `Compare to last year (2019)`
  },
  {
      title: 'Costs',
      value:'$8,310', 
      status: 0.7,
      subtitle: `Compare to last year (2019)`
  }
]

export const ActiveMembersData = [{
  name: 'Members',
  data: [25, 15, 41, 25, 44, 12, 36, 19, 54]
}]

export const NewMembersData = [{
  img: "/img/avatars/thumb-2.jpg",
  title: "Software Engineer",
  name: "Terrance Moreno",
},
{
  img: "/img/avatars/thumb-3.jpg",
  title: "UI/UX Designer",
  name: "Ron Vargas",
},
{
  img: "/img/avatars/thumb-4.jpg",
  title: "HR Executive",
  name: "Luke Cook",
},
{
  img: "/img/avatars/thumb-5.jpg",
  title: "Frontend Developer",
  name: "Joyce Freeman",
},
{
  img: "/img/avatars/thumb-6.jpg",
  title: "Compliance Manager",
  name: "Samantha Phillips",
}]

export const RecentTransactionData = [
  {
      id: '#5331',
      name: 'Clayton Bates',
      date: '8 May 2020',
      amount: '$137.00',
      status: 'Approved',
      avatarColor: '#04d182'
  },
  {
      id: '#5332',
      name: 'Gabriel Frazier',
      date: '6 May 2020',
      amount: '$322.00',
      status: 'Approved',
      avatarColor: '#fa8c16'
  },
  {
      id: '#5333',
      name: 'Debra Hamilton',
      date: '1 May 2020',
      amount: '$543.00',
      status: 'Pending',
      avatarColor: '#1890ff'
  },
  {
      id: '#5334',
      name: 'Stacey Ward',
      date: '28 April 2020',
      amount: '$876.00',
      status: 'Rejected',
      avatarColor: '#ffc542'
  },
  {
      id: '#5335',
      name: 'Troy Alexander',
      date: '28 April 2020',
      amount: '$241.00',
      status: 'Approved',
      avatarColor: '#ff6b72'
  },
];