import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Badge,
  Select,
  Form,
  Table,
  Tag,
  Row,
  Col,
  Space,
  Button,
  App,
  Result,
  Input,
  Modal,
  Drawer,
  Alert,
} from "antd";
import { ApiPost } from "../../../Supports/ApiManager";
import {
  PaymentColors,
  PaymentStatusColor,
  showDatePass,
  validateEmail,
} from "../../../Helper";
import { FilterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  CheckCircleOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { SocketMerchantReceiveTransactionUpdate } from "../../../Storage/SocketMerchantReceiveTransactionUpdate";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { isMobile } from "react-device-detect";
import PaymentDetails from "./PaymentDetails";

const ShowTimePass = ({ creation_date }) => {
  const [parent, enableAnimations] = useAutoAnimate(/* optional config */);

  useEffect(() => {
    enableAnimations(true);
  }, [parent]);

  const [getShow, setShow] = useState({
    saat: 0,
    dakika: 0,
    saniye: 0,
  });

  setInterval(() => {
    const getCalDate = showDatePass(
      dayjs(creation_date).format("YYYY-MM-DD HH:mm:ss")
    );

    setShow(getCalDate);
  }, 1000);

  if (getShow.saat > 0) {
    return (
      <div
        ref={parent}
      >{`${getShow.saat} Saat ${getShow.dakika} Dakika ${getShow.saniye} Saniye`}</div>
    );
  } else {
    if (getShow.dakika === 0) {
      return <div ref={parent}>{`${getShow.saniye} Saniye`}</div>;
    }
    if (getShow.dakika > 0) {
      return (
        <div
          ref={parent}
        >{`${getShow.dakika} Dakika ${getShow.saniye} Saniye`}</div>
      );
    }
  }
};

const GetQuestionView = (props) => {
  const { message, notification, modal } = App.useApp();

  const [getQuestion, setQuestion] = useState("");
  const [getQuestionResult, setQuestionResult] = useState("");
  const [getAnswer, setAnswer] = useState("");

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect((props) => {
    const getStepOne = getRandomNumber(1, 4);
    const getStepTwo = getRandomNumber(1, 4);

    setQuestion(` ${getStepOne} + ${getStepTwo} `);
    setQuestionResult(getStepOne + getStepTwo);
  }, []);

  const emailInput = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <Space>
      <Input
        onKeyUp={(ev) => {
          if (ev.keyCode === 13) {
            if (getAnswer === getQuestionResult) {
              props?.onConfirm(true);
            } else {
              props?.onConfirm(false);
            }
          }
        }}
        ref={emailInput}
        prefix={
          <>
            {" "}
            <QuestionCircleOutlined style={{ marginRight: 10 }} /> {getQuestion}{" "}
            <span style={{ marginLeft: 10 }}>=</span>{" "}
          </>
        }
        onChange={(e) => {
          setAnswer(parseInt(e.target.value));

          if (parseInt(e.target.value) === getQuestionResult) {
            props?.onConfirm(true);
          } else {
            props?.onConfirm(false);
          }
        }}
        inputMode={"numeric"}
        className={"questionInput"}
        style={{ marginTop: 15, marginBottom: 15 }}
        block
        placeholder={"Cevap"}
        suffix={
          <Button
            onClick={() => {
              if (getAnswer === getQuestionResult) {
                props?.onConfirm(true);
              } else {
                props?.onConfirm(false);
              }
            }}
            type='primary'
            icon={<CheckCircleOutlined />}
          >
            Doğrula
          </Button>
        }
      />
    </Space>
  );
};

const WaitingRoomSelectMerchantKey = "WAITINGROOM_SELECT_MERCHANTS";

const WaitingRoom = ({ onSuccess }) => {
  const [getSocketReceive, setSocketReceive] = useRecoilState(
    SocketMerchantReceiveTransactionUpdate
  );
  const { message, notification, modal } = App.useApp();

  const [getLoading, setLoading] = useState(false);
  const [getRecords, setRecords] = useState([]);
  const [getActionBtnID, setActionBtnID] = useState(null);
  const [openDetailsTransaction, setDetailsTransaction] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const getSelectMerchantsStorage = () =>
    JSON.parse(localStorage.getItem(WaitingRoomSelectMerchantKey) ?? "[]");

  const [getMerchants, setMerchants] = useState([]);
  const [getSelectMerchants, setSelectMerchants] = useState(
    getSelectMerchantsStorage()
  );

  const [getBlinkAction, setBlinkAction] = useState(false);
  const [getAudio, setAudio] = useState(false);
  const getAudioEffect = useRef(
    typeof Audio !== "undefined" ? new Audio("/bip.mp3") : null
  );

  useEffect(() => {
    ApiPost("/merchant/payment/merchants")
      .then((response) => {
        if (response.status !== "success") {
          throw new Error(response.message);
        }

        setMerchants(response.result);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message === undefined
            ? error.message
            : error?.response?.data?.message
        );
        console.error("Error : ", error);
      });
  }, []);

useEffect(()=>{

       if(window.localStorage.getItem("notification_audio") === null){
           window.localStorage.setItem("notification_audio","passive");
           setAudio(false);
       }

       getAudioEffect.loop     = false;
       getAudioEffect.autoplay = false;

   },[getAudio])

   useEffect(()=>{

       if(["active","passive"].includes(window.localStorage.getItem("notification_audio"))){
           if(window.localStorage.getItem("notification_audio") === "active"){
               setAudio("active");
           }
           if(window.localStorage.getItem("notification_audio") === "passive"){
               setAudio(false);
           }
       }

   },[])

   useEffect(()=>{

       try{
           if(getAudioEffect !== null){
               if(getAudio && getBlinkAction === true){
                   getAudioEffect.current.currentTime  = 0;
                   getAudioEffect.current.play().catch(error => {
                       console.info("İşlem Güncellemesi, Bildirim sesi almak için sesi açınız yada ekranda mouse işlemi yapınız.",error);
                   });
                   setBlinkAction(false);
               }
           }
       }catch (e){
           console.error("Bildirim Sesi Hatası : ",e);
       }

 },[getBlinkAction])

  // Update network status
  const handleStatusChange = () => {
    setIsOnline(navigator.onLine);
    if (navigator.onLine) {
      getData();
    } else {
      console.log("Network connection failed");
    }
  };

  useEffect(() => {
    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    window.addEventListener("focus", handleStatusChange);
    return () => {
      window.removeEventListener("focus", handleStatusChange);
    };
  }, []);

  const inRoomAction = (transaction_id) => {
    setActionBtnID(transaction_id);

    ApiPost(`/payment/${transaction_id}/status/update`, {
      status: "payment_on_process",
    })
      .then((response) => {
        if (response.status !== "success") {
          throw new Error(response.message);
        }

        message.success(response.message);
        setActionBtnID(null);

        // openDetailsFn(transaction_id);

        message.destroy();
        message.success("İşlemi üzerinize aldınız", 1500);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message === undefined
            ? error.message
            : error?.response?.data?.message
        );
        setActionBtnID(null);
        console.error("Error : ", error);
      });
  };

  const columns = useCallback(
    [
      {
        title: "Kullanıcı Adı",
        dataIndex: "client_username",
        align: "left",
        width: 100,
        render: (_, { client_username }) => client_username,
      } /*

        {
            title: 'Kullanıcı E-Posta',
            dataIndex: 'client_email',
            align: 'left',
            render: (_,{client_email}) => {
                if(validateEmail(client_email)){
                    return <Tag color={"success"}>{client_email}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        },
        */,
      {
        title: "Method",
        dataIndex: "method",
        align: "left",
        render: (_, { method }) => (
          <Space>
            {method.label}{" "}
            <div
              style={{
                width: "100%",
                padding: "0px 10px",
                color: "#fff",
                background: method.value.includes("WITHDRAW") ? "red" : "green",
              }}
            >{method.value.includes("WITHDRAW") ? "Çekim" : "Yatırım"}</div>{" "}
          </Space>
        ),
      },
      {
        title: "Kart Bilgisi",
        dataIndex: "table_view",
        align: "left",
        render: (_, { table_view }) =>
          table_view.map((item) => {
            if (
              item.label === "Banka" &&
              ![null, "", " "].includes(item.value)
            ) {
              return (
                <img
                  src={`/banks/${window
                    .trToEn(item.value.replace(" ", ""))
                    .replace(/\s/g, "")}.svg`}
                  style={{ width: 100, height: "fit-content" }}
                />
              );
            } else {
              return (
                <Tag color={"#86868600"} style={{color:"#000"}}>{`${item.value}`}</Tag>
              );
            }
          }),
      },
      {
        title: "Tutar",
        dataIndex: "amount",
        render: (_, { amount, currency }) => {
          const formatter = new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: currency.value,
          });
          return (
            <Space className={"spaceTag"} style={{display:"flex"}}>
              <Tag
                color={PaymentColors[currency.value] ?? "#000000"}
                style={{ display:"flex", width:"100%", margin:"0", justifyContent:"center"}}
              >
                {formatter.format(amount)}
              </Tag>
            </Space>
          );
        },
      } /*
        {
            title: 'Durum',
            dataIndex: 'status',
            render: (_,{status}) => {

                return <Space>
                    <Tag color={PaymentStatusColor[status.value] ?? "black"} style={{border: "1px dashed purple"}}>{status.label}</Tag>
                </Space>;
            }
        },*/,
      {
        title: "İşlem Tarihi",
        dataIndex: "creation_date",
        render: (_, { creation_date }) => dayjs(creation_date).format("HH:mm"),
      },
      {
        title: "İşlemler",
        dataIndex: "id",
        align: "center",
        render: (_, { transaction_id }) => {
          return (
            <>
              <Button
                onClick={() => {
                  message.success("Ödeme başlatıldı.");
                  inRoomAction(transaction_id);
                }}
                block
                loading={getActionBtnID === transaction_id}
                type='primary'
                icon={<SaveOutlined />}
              >
                Ödemeyi Başlat
              </Button>
            </>
          );
        },
      },
    ],
    [getActionBtnID, getMerchants]
  );

  const getData = () => {
    setLoading(true);
    ApiPost("/payment/list/wait-room", {
      merchants: getSelectMerchantsStorage(),
    })
      .then((response) => {
        if (response.status !== "success") {
          throw new Error(response.message);
        }

        // const oldRecordIds = getRecords.map((x) => x.id);
        // const newRecordIds = [];

        setRecords([]);
        response.result.map((item) => {
          // if(!oldRecordIds.includes(item.id)) {
          //     newRecordIds.push(item.id);
          // }
          return setRecords((getRecords) => [...getRecords, item]);
        });

        // if(newRecordIds.length > 0) {
        //     setBlinkAction(true);
        // }

        setLoading(false);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message === undefined
            ? error.message
            : error?.response?.data?.message
        );
        setLoading(false);
        console.error("Error : ", error);
      });
  };

  useEffect(getData, [getSocketReceive, getSelectMerchants]);

  const closeDetailsFn = () => {
    setOpenDetails(false);
    setDetailsTransaction(null);
  };

  const openDetailsFn = (transaction_id) => {
    setDetailsTransaction(transaction_id);
    setOpenDetails(true);
  };

  return (
    <>
      <>
        {!isMobile && (
          <Table
            // loading={getLoading}
            style={{ width: "100%" }}
            scroll={{ x: 1000 }}
            size={"small"}
            columns={columns}
            dataSource={getRecords}
            bordered
            rowKey={"id"}
            key={"id"}
            pagination={false}
          />
        )}

        {isMobile && (
          <div style={{ width: "100%" }}>
            {getRecords.length > 0 &&
              getRecords.map((item) => {
                return (
                  <div
                    style={{
                      marginBottom: "10px",
                      border: "1px solid #c4b8b8",
                      padding: "6px",
                      borderRadius: "6px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    {columns.map((colItem) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          margin: "6px 0px",
                          background: "#f3f3f3",
                          borderRadius: 6,
                        }}
                      >
                        <div
                          style={{
                            padding: "3px 15px",
                            width: 140,
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 10,
                          }}
                        >
                          {colItem["title"]}
                        </div>
                        <div
                          style={{
                            padding: "3px 15px",
                            flex: "auto",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 10,
                          }}
                        >
                          {colItem["render"](item, item)}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}

            {getRecords.length === 0 && (
              <>
                <Result status='warning' title='İşlem Bulunamadı' />
              </>
            )}
          </div>
        )}

        <Drawer
          title='İşlem Detayı'
          width={650}
          placement='right'
          onClose={closeDetailsFn}
          bodyStyle={{ padding: 20 }}
          open={openDetails}
        >
          {openDetailsTransaction !== null && (
            <PaymentDetails
              onClose={closeDetailsFn}
              transaction={{ transaction_id: openDetailsTransaction }}
            />
          )}
        </Drawer>
      </>
    </>
  );
};

export default WaitingRoom;
