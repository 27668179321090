import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Drawer, Tabs, Statistic, Card, Col, Row, FloatButton} from "antd";
import WaitingRoom from "./Payment/WaitingRoom";
import OnProcessRoomSingle from "./Payment/OnProcessRoomSingle";
import TransactionHistory from "./Payment/TransactionHistory";

import StickyBox from 'react-sticky-box';
import isMobile from "is-mobile";
import {StockOutlined} from "@ant-design/icons";
import {ApiPost} from "../../Supports/ApiManager";
import CountUp from 'react-countup';
import {useRecoilState} from "recoil";
import {SocketLiveTransactionsState} from "../../Storage/SocketLiveTransactions";
import {SocketMerchantReceiveTransactionUpdate} from "../../Storage/SocketMerchantReceiveTransactionUpdate";

const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
        offsetTop={0}
        offsetBottom={20}
        style={{
            zIndex: 1,
        }}>
        <DefaultTabBar {...props} />
    </StickyBox>
);


const formatter = (value) => <CountUp end={value} separator="," />;


const colSizeSpannerDesktop = {
    1: 24,
    2: 12,
    3: 8,
    4: 6,
    5: 6,
    6: 6,
    7: 6
}

const PaymentScreen = () => {

    const [getStatic,setStatic]                 = useState([]);
    const [getHistoryDrawer,setHistoryDrawer]   = useState(false);
    const [getDailyStatic,setDailyStatic]   = useState(false);
    const [getActiveTab,setActiveTab]   = useState("1");
    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);


    useEffect(() => {   

        ApiPost("/payment/agent/dashboard")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setStatic([]);
                response.result.map(item => {
                    setStatic(getStatic => [...getStatic,item]);
                });

            })
            .catch(error => {
                console.log(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })


    },[getSocketReceive]);


    return (<>

        <div style={{padding:0}}>


<Card style={{borderRadius:"0", borderBottom:"none"}}>
        <Row gutter={0}>
        <Col xs={24} sm={24} md={24}  lg={24} xl={24} xxl={24} style={{padding:10}}>
        <Card title={
                <Tabs
                style={{height:"100%", minHeight:"450px", paddingTop:5,paddingBottom:25}}
                renderTabBar={renderTabBar}
                defaultActiveKey={getActiveTab}
                activeKey={getActiveTab}
                onChange={(getTab) => setActiveTab(getTab)}
                items={[
                    {
                        key: '1',
                        label: 'Ödeme Talepleri',
                        children: <WaitingRoom />,
                    }
                ]}/>} className="payments-card-body"/>
        </Col>
        <Col xs={24} sm={24} md={24}  lg={24} xl={24} xxl={24} style={{padding:10}}>
        <Card title={
                <Tabs
                style={{height:"100%", minHeight:"450px", paddingTop:5,paddingBottom:25}}
                renderTabBar={renderTabBar}
                defaultActiveKey={getActiveTab}
                activeKey={getActiveTab}
                onChange={(getTab) => setActiveTab(getTab)}
                items={[
                    {
                        key: '1',
                        label: 'İşlemlerim',
                        children: <OnProcessRoomSingle/>,
                    }
                ]}/>} className="payments-card-body"/>
        </Col>
         </Row>
         </Card>


        </div>
    </>)
};


export default PaymentScreen;