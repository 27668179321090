import React, {useCallback, useEffect, useState} from "react";
import {
    App,
    Badge,
    Button,
    Card,
    Col,
    DatePicker,
    Drawer,
    Dropdown,
    Form,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Tag,
    Tooltip
} from "antd";
import {PaymentColors, PaymentStatusColor, validateEmail} from "../../../../../Helper";
import {ApiPost} from "../../../../../Supports/ApiManager";
import dayjs from "dayjs";
import {FilterOutlined, CloudUploadOutlined,FolderViewOutlined, EyeOutlined, CloudDownloadOutlined, CloudServerOutlined, UnorderedListOutlined} from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/tr_TR";
import ReactJson from 'react-json-view';


const CallbackHistory = () => {


    const { message, notification, modal }                      = App.useApp();

    const [searchForm]                                          = Form.useForm();

    const [clientReady, setClientReady]                         = useState(true);


    const [getRecordsLoading, setRecordsLoading]        = useState(false);
    const [getRecords, setRecords]                      = useState([]);


    const [getJsonViewDrawer,setJsonViewDrawer]         = useState(false);
    const [getJsonView, setJsonView]                    = useState("");

    const [getFilters, setFilters] = useState({});

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setRecords([]);
        }
    };

    useEffect(() => {

        setClientReady(true);

    }, []);

    useEffect(() => {

        if(!(getFilters?.start || getFilters?.finish)) {
            return;
        }

        setRecordsLoading(true);

        ApiPost(`/merchant/callback/history/search?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`, getFilters)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: response.result.total
                    },
                });

                setRecords([]);
                response.result.data.map(item => setRecords(current => [...current,item]));

                if(response.result.length === 0){
                    message.error("Kayıt Bulunamadı");
                }

                setRecordsLoading(false);


            }).catch(error =>{
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    }, [getFilters?.start, getFilters?.finish, tableParams?.pagination?.current,tableParams?.pagination?.pageSize]);

    const historyApiRequestQuery = (values) => {

        setFilters({
            start: dayjs(values.dates[0]).format("YYYY-MM-DD HH:mm:ss"),
            finish: dayjs(values.dates[1]).format("YYYY-MM-DD HH:mm:ss")
        });

    };

    const columns = useCallback([
        {
            title: 'İşlem No',
            dataIndex: 'payment_request',
            align: 'center',
            width:75,
            render: (_,{payment_request}) => <Tooltip placement={"right"} title={payment_request?.transaction_id}> <EyeOutlined /> </Tooltip>
        },
        {
            title: 'Sipariş No',
            dataIndex: 'payment_request',
            align: 'left',
            render: (_,{payment_request}) => payment_request?.order_number
        },
        {
            title: 'Personel',
            dataIndex: 'payment_request',
            align: 'left',
            render: (_,{payment_request}) => {
                if(payment_request?.agent !== undefined){
                    return `${payment_request?.agent?.name} ${payment_request?.agent?.surname}`
                }else{
                    <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                }
            }
        },
        {
            title: 'İstek İçeriği',
            dataIndex: 'payment_request',
            align: 'left',
            render: (_,{payment_request}) => {

                const getViewInside = (
                    <>

                        { payment_request?.client_username !== null     && <div><div color={"success"} style={{borderBottom: "1px solid black"}}>Kullanıcı Adı : {payment_request?.client_username}</div></div> }
                        { payment_request?.client_description  !== null  && <div><div color={"success"} style={{borderBottom: "1px solid black"}}>Açıklama : {payment_request?.client_description}</div></div> }
                        { payment_request?.client_custom  !== null       && <div><div color={"success"} style={{borderBottom: "1px solid black"}}>Özel İçerik : {payment_request?.client_custom}</div></div> }
                        { validateEmail(payment_request?.client_email)      && <div><div color={"success"} style={{borderBottom: "1px solid black"}}>E-Posta : {payment_request?.client_email}</div></div> }

                    </>
                );

                return <Button type={"link"} shape={"round"} icon={<FolderViewOutlined />} onClick={() => modal.success({ okText:"Kapat", title:"İstek Talep İçeriği", content:getViewInside })}>İstek Talebi göster</Button>
            }
        },
        {
            title: 'Method',
            dataIndex: 'method',
            align: 'payment_request',
            render: (_,{payment_request}) => payment_request.method.label
        },
        {
            title: 'Tutar',
            dataIndex: 'payment_request',
            render: (_,{payment_request}) => {
                const formatter = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: payment_request.currency.value
                });
                return <Space>
                    <Tag color={PaymentColors[payment_request.currency.value] ?? "#000000"} style={{border: "1px dashed purple"}}>{payment_request.currency.value}</Tag>
                    <Tag color={PaymentColors[payment_request.currency.value] ?? "#000000"} style={{border: "1px dashed purple"}}>{formatter.format(payment_request.amount)}</Tag>
                </Space>;
            }
        },
        {
            title: 'Durum',
            dataIndex: 'payment_request',
            render: (_,{payment_request}) => {

                return <Space>
                    <Tag color={PaymentStatusColor[payment_request.status.value] ?? "black"} style={{border: "1px dashed purple"}}>{payment_request.status.label}</Tag>
                </Space>;
            }
        },
        {
            title: 'Servis',
            dataIndex: 'status',
            render: (_,response) => {

                return <Space>

                    <Dropdown menu={{items: [
                            {
                                icon: <CloudUploadOutlined />,
                                label: "Gönderilen İstek",
                                onClick: () => {
                                    setJsonViewDrawer(true);
                                    setJsonView(response?.request_content)
                                }
                            },
                            {
                                icon: <CloudDownloadOutlined />,
                                label: "Alınan Yanıt",
                                onClick: () => {
                                    setJsonViewDrawer(true);
                                    setJsonView(response?.response_content)
                                }
                            },
                            {
                                icon: <CloudServerOutlined />,
                                label: "Alınan Yanıt Başlığı",
                                onClick: () => {
                                    setJsonViewDrawer(true);
                                    setJsonView(response?.status_header)
                                }
                            }
                        ] }}>
                        <Button
                            type={"primary"}
                            shape={"round"}
                            icon={<UnorderedListOutlined />}>
                            Gönderilen
                        </Button>
                    </Dropdown>

                </Space>;
            }
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'creation_date',
            render:(_,{creation_date}) => dayjs(creation_date).format("DD/MM/YYYY HH:mm:ss")
        }
    ],[]);


    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return(<>
                <Form form={searchForm} name="horizontal_login" layout="horizantal" onFinish={historyApiRequestQuery}>


                        <Row gutter={25}>
                            <Col span={12}>
                                <Form.Item
                                    label={"Tarih Aralığı"}
                                    name="dates"
                                    rules={[{required: true, message: 'Tarih aralığı seçimi zorunludur!' } ]}>
                                    <DatePicker.RangePicker style={{width:"100%"}}  showTime={{ format: 'HH:mm' }}       format="YYYY-MM-DD HH:mm" locale={locale} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Space>
                                <Form.Item shouldUpdate style={{margin:0}}>
                                    {() => (
                                        <Button
                                            icon={<FilterOutlined />}
                                            type="primary"
                                            htmlType="submit"
                                            disabled={ !clientReady ||  !searchForm.isFieldsTouched(true) ||  !!searchForm.getFieldsError().filter(({ errors }) => errors.length).length }>
                                            Bul
                                        </Button>
                                    )}
                                </Form.Item>
                            </Space>
                            </Col>
                        </Row>
                </Form>
        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", marginTop:"5px"}}>
            <div style={{flex:1}}>
                <Table
                    loading={getRecordsLoading}
                    style={{ width:"100%"}}
                    size={"small"}
                    columns={columns}
                    dataSource={getRecords}
                    rowKey={"id"}
                    bordered
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                />

            </div>

            <Drawer
                title="İstek/Yanıt içeriği"
                width={"50%"}
                placement="right"
                onClose={()=>setJsonViewDrawer(false)}
                open={getJsonViewDrawer}>
                <ReactJson src={getJsonView} />
            </Drawer>

        </div>
    </>)
};

export default CallbackHistory;