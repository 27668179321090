import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Row,
  Button,
  Col,
  Statistic,
  Card,
  Avatar,
  List,
  message,
  Segmented,
} from "antd";
import MerchantLiveTransactionsCharts from "../../Components/MerchantLiveTransactionsCharts";
import CountUp from "react-countup";
import MerchantStaticCharts from "../../Components/MerchantStaticCharts";
import { useRecoilState } from "recoil";
import { AuthAccount } from "../../Storage/AuthAccount";
import ErrorPageRouter from "../Respose/ErrorPageRouter";
import { SocketMerchantReceiveTransactionUpdate } from "../../Storage/SocketMerchantReceiveTransactionUpdate";
import { ApiPost } from "../../Supports/ApiManager";
import DailyDashboardFinance from "./Finance/DailyDashboardFinance";
import ChartWidget from "../../Components/ChartWidget";
import MonthlyChart from "../../Components/MonthlyChart";
import { VisitorChartData } from "./DefaultDashboardData";
import { MonthlyChartData } from "./DefaultDashboardData";

const startAt = new Date();
startAt.setHours(0);
startAt.setMinutes(0);

const finishAt = new Date();
finishAt.setHours(23);
finishAt.setMinutes(59);

const HomeScreen = () => {
  const [visitorChartData] = useState(VisitorChartData);
  const [monthlyChartData] = useState(MonthlyChartData);

  const [getStatic, setStatic] = useState("today");
  const [getSocketReceive, setSocketReceive] = useRecoilState(
    SocketMerchantReceiveTransactionUpdate
  );
  const [getAccount] = useRecoilState(AuthAccount);

  if (["root", "admin", "operator"].includes(getAccount?.role?.value)) {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Card style={{ borderRadius: "0", borderBottom: "none" }}>
            <Row gutter={0}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={12}
                style={{ padding: 10 }}
              >
                <Card title={"Günlük İşlemler"} bodyStyle={{ minHeight: 120 }}>
                  <ChartWidget
                    series={visitorChartData.series}
                    xAxis={visitorChartData.categories}
                    height={"250px"}
                  />
                </Card>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={12}
                style={{ padding: 10 }}
              >
                <Card title={"Aylık İşlemler"} bodyStyle={{ minHeight: 120 }}>
                  <MonthlyChart
                    series={monthlyChartData.series}
                    xAxis={monthlyChartData.categories}
                    height={"250px"}
                  />
                </Card>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                style={{ padding: 10 }}
              >
                <Card
                  title={"Son Gelen İşlemler"}
                  bodyStyle={{ minHeight: 370 }}
                >
                  <DailyDashboardFinance
                    startAt={startAt}
                    finishAt={finishAt}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default HomeScreen;
