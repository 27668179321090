// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAEf149SxBazOX-Acono-0TpHl6jkcLljE",
    authDomain: "payment-PasoPay.com.firebaseapp.com",
    projectId: "payment-PasoPay.com",
    storageBucket: "payment-PasoPay.com.appspot.com",
    messagingSenderId: "806182479036",
    appId: "1:806182479036:web:946cc2b207489d9adc9cb9",
    measurementId: "G-BHWMJYKSZH"
};


const onMessageListener = (Messaging) =>
    new Promise((resolve) => {
        onMessage(Messaging, (payload) => {
            resolve(payload);
        });
    });


export {
    initializeApp,
    firebaseConfig,
    getAnalytics,
    getMessaging,
    isSupported,
    getToken,
    onMessageListener
};

