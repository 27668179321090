import React, {useState} from "react";
import {Form, Checkbox, Input, Button, App} from "antd";
import {Player} from "@lottiefiles/react-lottie-player";
import getAnimate from "../../Assets/lottiefiles/paymentAnimV4.json";
import {useRecoilState, useRecoilValue} from "recoil";
import {ApiPost} from "../../Supports/ApiManager";
import {useNavigate} from "react-router-dom";
import {AuthControlStorage} from "../../Storage/AuthControlStorage";
import { isMobile } from "react-device-detect";




const Login = () => {

    const { message, notification, modal }                      = App.useApp();

    const navigate                          = useNavigate();
    const [get2FA,set2FA]                   = useState(false);
    const [initLoading,setLoading]          = useState(false);
    const [getAuth,setAuth]                 = useRecoilState(AuthControlStorage);

    const onFinish = values => {

        setLoading(true);

        ApiPost("/auth/login",{
            username: values.username,
            password: values.password,
            ["2fa"]: get2FA ? values["2fa"] : "login"
        })
            .then(async response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                window.localStorage.clear();

                window.localStorage.setItem("token",response.result.token)

                ApiPost("/merchant/view")
                    .then(responseView => {

                        if(responseView.status !== "success"){
                            throw new Error(responseView.message);
                        }

                        window.localStorage.setItem("merchant_id",responseView.result.merchant_id)

                        setLoading(false);
                        setAuth(true);
                        navigate("/app");

                    })
                    .catch(error => {
                        message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                        navigate("/auth/login");
                    })

            })
            .catch(error => {
                setLoading(false);
                if(error?.response?.data?.result !== undefined){
                    if(error?.response?.data?.result["2fa"]){
                        set2FA(true)
                    }else{
                        message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                        navigate("/auth/login");
                    }
                }else{
                    message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                    navigate("/auth/login");
                }
            })


    };

    const onFinishFailed = errorInfo => {
        console.error('Failed:', errorInfo);
    };

    return(<>

        <div className="login-page">
        <div style={{width: isMobile ? "100%" : "33.3%", height:"100vh", display:"flex", flexDirection:"row", alignItems:"center", background:"white"}}>
        <div className="login-box" style={{display: isMobile ? "flex" : "none"}}>
                <span style={{cursor:"pointer"}}>Mobile Locked</span>
            </div>
            <div className="login-box" style={{margin: isMobile ? "0px 40px" : "0px 90px", display: isMobile ? "none" : ""}}>
                <Form
                    disabled={initLoading}
                    autoComplete={"off"}
                    name="login-form"
                    style={{fontFamily:"nunito sans"}}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                    <img src="https://uzbepay.com/assets/uzbe.png" height={70} style={{marginBottom:"20px"}}/>
                    <div style={{display:"flex", flexDirection:"column", marginBottom:"40px" }}>                    
                        <span style={{fontSize:"18px", fontWeight:700}}>Hoşgeldiniz</span>
                        <span>Giriş Yapmak İçin Kullanıcı Bilgilerinizi Giriniz</span>
                    </div>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'E-posta gereklidir!' },{ type: "email", message: 'E-posta yanlış!' }]}>
                        <Input
                            autoComplete={"off"}
                            placeholder="E-posta adresi"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Şifre gereklidir!' }]}>
                        <Input.Password
                            autoComplete={"off"}
                            placeholder="Şifreniz"
                        />
                    </Form.Item>


                    {
                        get2FA &&
                        <Form.Item
                            name="2fa"
                            rules={[{ required: true, message: 'Doğrulama kodunuzu girin!' }]}>
                            <Input
                                autoComplete={"off"}
                                placeholder="Doğrulama Kodu"
                            />
                        </Form.Item>
                    }

                    <Form.Item style={{marginTop:25}}>
                        <Button  type="default" htmlType="submit" className="login-form-button">
                            Giriş Yap
                        </Button>
                    </Form.Item>
                    <div style={{display:"flex", flexDirection:"column", marginTop:"20px"}}>                    
                        <a href={"#"} style={{cursor:"pointer"}}>Şifrenizi mi unuttunuz ?</a>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", marginTop:"20px"}}>                    
                        <span style={{cursor:"pointer"}}>© Uzbepay / 2024</span>
                    </div>
                </Form>
            </div>
            </div>
            <div style={{width:"70%", display: isMobile ? "none" : "" , background:"url(https://uzbepay.com/assets/login-bg.png)no-repeat", minHeight:"100vh", backgroundSize:"cover"}}>

            </div>
        </div>

    </>);

};


export default Login