import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Badge, Button, Col, Drawer, Row, Space, Table, Dropdown, Tag, App} from "antd";
import {useRecoilState} from "recoil";
import {PaymentColors, PaymentStatusColor, validateEmail} from "../../../../../../Helper";
import {SocketMerchantReceiveTransactionStatusUpdate} from "../../../../../../Storage/SocketMerchantReceiveTransactionStatusUpdate";
import {ApiPost} from "../../../../../../Supports/ApiManager";
import dayjs from "dayjs";
import {DownOutlined, UserAddOutlined, SettingOutlined, HistoryOutlined} from "@ant-design/icons";
import TMSettingsAdmin from "./TMSettingsAdmin";
import TMCreateMerchant from "./TMCreateAdmin";

const TMManagerAdmin = (props) => {

    const { message, notification, modal }                      = App.useApp();

    const [getStatusReceive,]                                               = useRecoilState(SocketMerchantReceiveTransactionStatusUpdate);
    const [getLoading,setLoading]                                           = useState(false);
    const [getRecords,setRecords]                                           = useState([]);
    const [getDetailsID, setDetailsID]                                      = useState(null);
    const [getDetails, setDetails]                                          = useState({});
    const [getSettingsDrawer, setSettingsDrawer]                            = useState(false);
    const [getHistoryDrawer, setHistoryDrawer]                              = useState(false);
    const [getPersonalCreateDrawer, setPersonalCreateDrawer]                = useState(false);


    const openDetailsFn = (details) => {

        setDetailsID(details.id);
        setSettingsDrawer(false);

        ApiPost(`/transfer/method/${details.id}/view`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setSettingsDrawer(true);
                setDetails(details);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    };

    const closeDetailsFn = () => {

        setDetailsID(null);
        setSettingsDrawer(false);

    }



    const getTransferAccounts = () => {

        setLoading(true);
        ApiPost(`/transfer/methods`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setRecords([]);
                result.map(item => setRecords(getRecords => [...getRecords,item]));
                setLoading(false);


            })
            .catch(error => {
                setLoading(false);
                console.error("Error : ",error);
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    };


    useEffect(getTransferAccounts,[getStatusReceive])


    const columns = useCallback([
        {
            title: 'Method',
            dataIndex: 'prefix',
            align: 'left',
            render: (_,{prefix}) => prefix.label
        },
        {
            title: 'Başlık',
            dataIndex: 'title',
            align: 'left',
        },
        {
            title: 'Tutar',
            dataIndex: 'amount',
            align: 'left',

            render: (_,{currency,amount}) => {
                const formatter = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: currency.value
                });

                return (
                    <Space>
                        <Tag color={PaymentColors[currency.value] ?? "#000000"} style={{border: "1px dashed purple"}}>{formatter.format(amount)}</Tag>
                    </Space>
                )


            }
        },
        {
            title: 'Kayıt Açan',
            dataIndex: 'admin',
            align: 'left',
            render: (_,{admin}) => {
                if(admin){
                    return <Tag color={"success"}>{`${admin.name} ${admin.surname}`}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            render: (_,{status}) => {
                if(status.value === "active"){
                    return <Tag color={"success"}>Aktif</Tag>
                }else{
                    return <Tag color={"error"}>Pasif</Tag>
                }
            }
        },
        {
            title: 'Olş. Tarih',
            dataIndex: 'creation_date',
            render:(_,{creation_date}) => dayjs(creation_date).format("DD/MM/YYYY HH:mm:ss")
        },
        {
            title: 'İşlemler',
            dataIndex: 'id',
            align: "center",
            render:(_,details) => {

                return (
                    <Space>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        icon: <SettingOutlined />,
                                        label: "Ayarlar",
                                        key: '1',
                                        onClick:() => openDetailsFn(details)
                                    }
                                ],
                            }}
                            trigger={['click']}>
                            <Button loading={details.id === getDetailsID} type="primary" icon={<DownOutlined />} >
                                İşlem Seçiniz
                            </Button>
                        </Dropdown>


                    </Space>
                )

            }
        },
    ],[]);


    return(
        <div style={{}}>
           <Space style={{display:"flex", justifyContent:"flex-start", marginTop:"0px", marginBottom:"15px"}}>
           <Button onClick={()=>setPersonalCreateDrawer(true)} icon={<UserAddOutlined />} type={"primary"} >Hesap Oluştur</Button>
        </Space>
            <Row>
                <Col span={24}>
                    <Table
                        style={{ width:"100%"}}
                        scroll={{x:1300,y:400}}
                        size={"small"}
                        columns={columns}
                        dataSource={getRecords}
                        bordered
                        pagination={false}
                    />
                </Col>
            </Row>

            <Drawer
                title="Hesap Ayarı"
                width={600}
                placement="right"
                onClose={closeDetailsFn}
                open={getSettingsDrawer}>
                {getSettingsDrawer && <TMSettingsAdmin  id={getDetailsID} onChange={getTransferAccounts}/> }
            </Drawer>

            <Drawer
                title="Hesap Kayıt"
                width={600}
                placement="right"
                onClose={()=>setPersonalCreateDrawer(false)}
                open={getPersonalCreateDrawer}>
                {getPersonalCreateDrawer && <TMCreateMerchant onChange={() => {
                    setPersonalCreateDrawer(false);
                    getTransferAccounts();
                }} /> }
            </Drawer>


        </div>
    )

};


export default TMManagerAdmin;