import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Form, DatePicker, Button, Select, Space, Badge, Tag, Table, Input, App,} from "antd";
import 'dayjs/locale/tr';
import { PlusOutlined, PaperClipOutlined } from '@ant-design/icons';
import {ApiPost} from "../../../../../Supports/ApiManager";




const SiteCreate = (props) => {

    const { message, notification, modal }                      = App.useApp();

    const [PersonalForm]                                        = Form.useForm();
    const [getApps,setApps]                                     = useState([]);


    useEffect(()=>{

        ApiPost("/merchant/payment/method",{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setApps(response.result);

            }).catch(error =>{
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })


    },[])

    const onFinish = (values) => {

        ApiPost("/merchant/create",{
            title:                      values.title,
            description:                values.description,
            apps:                       values.apps,
            api_deposit_callback_url:   values.api_deposit_callback_url,
            api_withdraw_callback_url:  values.api_withdraw_callback_url,
            brand_domain:               values.brand_domain,
            live_status:                values.live_status,
            brand_status:               values.brand_status,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                message.success(response.message);
                props?.onChange(true);

            }).catch(error =>{
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                props?.onChange(false);
            })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return(<>

        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

            <Form
                form={PersonalForm}
                name="PersonalForm"
                layout={"vertical"}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Form.Item
                    hasFeedback
                    label="Başık"
                    name="title"
                    rules={[{required: true, message: 'This field is required!',} ]}>
                    <Input maxLength={50} showCount placeholder={"Başlık giriniz"} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Açıklama"
                    name="description"
                    rules={[{required: true, message: 'This field is required!',} ]}>
                    <Input.TextArea row={3} maxLength={100} showCount placeholder={"Başlık giriniz"} />
                </Form.Item>


                <Form.Item
                    hasFeedback
                    label="Method"
                    name="apps"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select placeholder={"Method seçiniz"} mode="multiple" options={getApps} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Durum"
                    name="live_status"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select placeholder={"Durum seçiniz"} options={[
                        {
                            key: Math.random().toString(),
                            label:"Aktif",
                            value:"active"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Pasif",
                            value:"passive"
                        }
                    ]} />
                </Form.Item>


                <Form.Item
                    hasFeedback
                    label="Markalama Durum"
                    name="brand_status"
                    rules={[{required: true, message: 'This field is required!'}]}>
                    <Select placeholder={"Durum seçiniz"} options={[
                        {
                            key: Math.random().toString(),
                            label:"Aktif",
                            value:"active"
                        },
                        {
                            key: Math.random().toString(),
                            label:"Pasif",
                            value:"passive"
                        }
                    ]} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Markalama domain"
                    name="brand_domain"
                    rules={[{required: true, message: 'This field is required!'},{pattern:"^([a-z0-9-]+\\.)+([a-zA-Z]{2,}|[a-z]{2,}\\.[a-z]{2})$", message: "Sadece küçük harf ve '-' ile yazınız"}]}>
                    <Input  prefix={<PaperClipOutlined />} placeholder={"Başlık giriniz"} />
                </Form.Item>


                <Form.Item
                    hasFeedback
                    label="Yatırım Callback"
                    name="api_deposit_callback_url"
                    rules={[{required: true, message: 'This field is required!'},{ type:"url", message:"Url Adresi Giriniz"}]}>
                    <Input prefix={<PaperClipOutlined />} placeholder={"Başarılı url giriniz"} />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    label="Çekim Callback"
                    name="api_withdraw_callback_url"
                    rules={[{required: true, message: 'This field is required!'},{ type:"url", message:"Url Adresi Giriniz"}]}>
                    <Input prefix={<PaperClipOutlined />} placeholder={"Başarısız url giriniz"} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 8,
                    }}>
                    <Button icon={<PlusOutlined />} type="primary" htmlType="submit" block>
                        Ouştur
                    </Button>
                </Form.Item>


            </Form>

        </div>
    </>)

};


export default SiteCreate;