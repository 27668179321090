import React, { useEffect, useState} from "react";
import {Badge, Table,   Row, Col, Space, Button, App, Result, Card, Modal, DatePicker} from "antd";
import {ApiPost} from "../../../Supports/ApiManager";
import {useRecoilState} from "recoil";
import {CalendarOutlined, SaveOutlined} from "@ant-design/icons";
import {SocketMerchantReceiveTransactionUpdate} from "../../../Storage/SocketMerchantReceiveTransactionUpdate";
import {isMobile} from "react-device-detect"; 
import dayjs from "dayjs";
import * as XLSX from 'xlsx-js-style';



const convertJSONToExcel = (fileName,jsonData) => {

    const wb = XLSX.utils.book_new();

    const alphabet = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];

    const ws = XLSX.utils.json_to_sheet(jsonData);
    ws['!cols'] = [
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, fileName);

    Object.keys(jsonData[0]).map((item,index)=> {
        wb.Sheets[fileName][`${alphabet[index]}1`].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "000000" },
            },
            font: {
                color: { rgb: "FFFFFF" }
            },
            alignment: {
                vertical:"center",
                horizontal:"center"
            }
        };
    })

    XLSX.writeFile(wb, `${fileName} ${dayjs(new Date()).format("DD-MM-YYYY HH-mm")}.xlsx`);
}


const DailyAgentsFinance = ({height}) => {

    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);
    const { message, notification, modal }      = App.useApp();

    const [getLoading,setLoading]               = useState(false);
    const [getRecords,setRecords]               = useState([]);
    const [getOperators,setOperators]           = useState([]);
    const [getMerchantTotal,setMerchantTotal]   = useState({});
    const [getBanksMonitor,setBanksMonitor]   = useState([]);
    const [getDay,setDay]                       = useState([dayjs(new Date()),dayjs(new Date())]);

    useEffect( () => {

        ApiPost("/merchant/payment/operators")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setOperators(response.result);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })


    },[])

    useEffect(()=>{

        Modal.destroyAll();
        setLoading(true);
        ApiPost("/payment/report/banks",{
            start_at: getDay[0].format("YYYY-MM-DD HH:mm"),
            finish_at: getDay[1].format("YYYY-MM-DD HH:mm")
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setBanksMonitor([]);
                Object.keys(response.result).map(item => setBanksMonitor(getBanksMonitor => [...getBanksMonitor,response.result[item]]));


            })
            .catch(error => {
                console.error("Error : ",error);
            })

        ApiPost("/payment/report/agent",{
            start_at: getDay[0].format("YYYY-MM-DD HH:mm"),
            finish_at: getDay[1].format("YYYY-MM-DD HH:mm")
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setRecords([]);
                response.result.map(item => setRecords(getRecords => [...getRecords,item]));
                setLoading(false);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoading(false);
                console.error("Error : ",error);
            })

        ApiPost("/payment/report/system",{
            start_at: getDay[0].format("YYYY-MM-DD HH:mm"),
            finish_at: getDay[1].format("YYYY-MM-DD HH:mm")
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMerchantTotal(response.result);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })

    },[getSocketReceive,getDay])


    const columnsBanks = [
        {
            title: 'Merchant',
            dataIndex: 'title',
            align: 'center',
            width: 200
        },
        {
            title: 'Bankalar',
            dataIndex: 'methods',
            render: (_,{methods}) => {


                return methods.map(itemMethods => {

                    return (<>

                       <Row gutter={25}>
                           <Col span={16}>
                               <Card bordered title={`${itemMethods.title} / Bankalar`}  style={{marginTop:15}} >
                                   <Table
                                       style={{margin:0}}
                                       dataSource={itemMethods.store.banks}
                                       columns={[
                                           {
                                               title: 'Banka',
                                               dataIndex: 'title',
                                               align: 'left',
                                               width: "33.3%",
                                               render: (_,bankData) => {
                                                   return (<img src={`/banks/${window.trToEn(bankData.title.replace(" ","")).replace(/\s/g, '')}.svg`} style={{width:100, height:"fit-content"}}/>)
                                               }
                                           },
                                           {
                                               title: 'Başarılı',
                                               dataIndex: 'success',
                                               align: 'left',
                                               width: "33.3%",
                                               render: (_,{success}) => new Intl.NumberFormat('tr-TR').format(success)
                                           },
                                           {
                                               title: 'Başarısız',
                                               dataIndex: 'failed',
                                               align: 'left',
                                               width: "33.3%",
                                               render: (_,{failed}) => new Intl.NumberFormat('tr-TR').format(failed)
                                           }
                                       ]}/>
                               </Card>
                           </Col>
                           <Col span={8}>
                               <Card bordered title={`${itemMethods.title} / Kasa`} style={{marginTop:15}}>
                                   <Table
                                       style={{margin:0}}
                                       dataSource={[
                                           {
                                               status:  "Başarılı",
                                               total:  new Intl.NumberFormat('tr-TR').format(itemMethods.store.bill.success),
                                           },
                                           {
                                               status:  "Başarısız",
                                               total:  new Intl.NumberFormat('tr-TR').format(itemMethods.store.bill.failed),
                                           }
                                       ]}
                                       columns={[
                                           {
                                               title: 'Durum',
                                               dataIndex: 'status',
                                               align: 'left',
                                               width:"50%"
                                           },
                                           {
                                               title: 'Tutar',
                                               dataIndex: 'total',
                                               align: 'left',
                                               width:"50%"
                                           },
                                       ]}/>
                               </Card>
                           </Col>
                       </Row>

                    </>);

                });

            }
        }
    ];


    const columnsCount = [
        {
            title: 'Personel',
            dataIndex: 'agent',
            align: 'left',
            filterMode: 'tree',
            width:"25%",
            filters: getOperators.map(item => ({text: item.label, value:item.value})),
            onFilter: (value, {agent}) => agent.id.includes(value),
            render: (_,{agent}) => `${agent?.name} ${agent?.surname}`
        },
        {
            title: 'İşlemde',
            dataIndex: 'count',
            align: 'center',
            width:"25%",
            filterMode: 'tree',
            render: (_,{count}) => Object.keys(count).map(item => ` (${count[item]["on_process"]}  ${item}) `)
        },
        {
            title: 'Onay',
            dataIndex: 'count',
            align: 'center',
            width:"25%",
            filterMode: 'tree',
            render: (_,{count}) => Object.keys(count).map(item => ` (${count[item]["success"]}  ${item}) `)
        },
        {
            title: 'İptal',
            dataIndex: 'count',
            width:"25%",
            align: 'center',
            filterMode: 'tree',
            render: (_,{count}) => Object.keys(count).map(item => ` (${count[item]["failed"]}  ${item}) `)
        }
    ];

    const columnsSum = [
        {
            title: 'Personel',
            dataIndex: 'agent',
            align: 'left',
            filterMode: 'tree',
            width:"25%",
            filters: getOperators.map(item => ({text: item.label, value:item.value})),
            onFilter: (value, {agent}) => agent.id.includes(value),
            render: (_,{agent}) => `${agent?.name} ${agent?.surname}`
        },
        {
            title: 'İşlemde',
            dataIndex: 'count',
            align: 'center',
            width:"25%",
            filterMode: 'tree',
            render: (_,{sum}) => Object.keys(sum).map(item =>  ` (${new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: item, }).format(sum[item]["on_process"])})`)
        },
        {
            title: 'Onay',
            dataIndex: 'count',
            align: 'center',
            width:"25%",
            filterMode: 'tree',
            render: (_,{sum}) => Object.keys(sum).map(item =>  ` (${new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: item, }).format(sum[item]["success"])})`)
        },
        {
            title: 'İptal',
            dataIndex: 'count',
            align: 'center',
            filterMode: 'tree',
            width:"25%",
            render: (_,{sum}) => Object.keys(sum).map(item =>  ` (${new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: item, }).format(sum[item]["failed"])})`)
        }
    ];

    const columnsSystem = [
        {
            title: 'Durum',
            dataIndex: 'title',
            align: 'left',
            filterMode: 'tree',
        },
        {
            title: 'Tutar',
            dataIndex: 'total',
            align: 'left',
            filterMode: 'tree',
            render: (_,{total,currency}) => new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: currency, }).format(total)
        }
    ];

    const methodAgents = [

        {
            title: 'Personel',
            dataIndex: 'agent',
            align: 'center',
            filterMode: 'tree',
            filters: getOperators.map(item => ({text: item.label, value:item.value})),
            onFilter: (value, {agent}) => agent.id.includes(value),
            render: (_,{agent}) => `${agent?.name} ${agent?.surname}`
        },
        {
            title: 'Method',
            dataIndex: 'agent',
            align: 'left',
            render: (_,{method}) => {

                return (<Row  gutter={5}>{

                    Object.keys(method).map(methodKey => Object.keys(method[methodKey]).map(currencyKey => {

                        const convertFormat = new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: currencyKey, });

                        return(<>
                            <Col span={6}>
                                <Table
                                    bordered
                                    style={{marginTop:10, marginBottom:10, width:"100%"}}
                                    size={"small"}
                                    pagination={false}
                                    title={() => `${method[methodKey][currencyKey].label} / ${currencyKey}`}
                                    dataSource={[
                                        {
                                            status: "İşlem",
                                            success: method[methodKey][currencyKey].count.success,
                                            failed: method[methodKey][currencyKey].count.failed,
                                            on_process: method[methodKey][currencyKey].count.on_process,
                                        },
                                        {
                                            status: "Tutar",
                                            success: convertFormat.format(parseFloat(method[methodKey][currencyKey].sum.success)),
                                            failed: convertFormat.format(parseFloat(method[methodKey][currencyKey].sum.failed)),
                                            on_process: convertFormat.format(parseFloat(method[methodKey][currencyKey].sum.on_process)),
                                        }
                                    ]} columns={[
                                    {
                                        title: 'Durum',
                                        dataIndex: 'status',
                                        align: 'center',
                                    },
                                    {
                                        title: 'Onay',
                                        dataIndex: 'success',
                                        key: 'success',
                                    },
                                    {
                                        title: 'İptal',
                                        dataIndex: 'failed',
                                        key: 'failed',
                                    },
                                    {
                                        title: 'İşlemde',
                                        dataIndex: 'on_process',
                                        key: 'on_process',
                                    }
                                ]} />
                            </Col>
                        </>)
                    }))
                }</Row>)

            }
        }
    ];

    const columnsAddionatal = [
        {
            title: 'Site',
            dataIndex: 'additional_domain',
            align: 'center',
            filterMode: 'tree',
            render: (_,{additional_domain}) => additional_domain === null ? "#" : additional_domain
        },
        {
            title: 'Müşteri',
            dataIndex: 'merchant',
            align: 'center',
            filterMode: 'tree',
        },
        {
            title: 'İşlemde',
            dataIndex: 'on_process',
            align: 'center',
            filterMode: 'tree',
            render: (_,{on_process}) => new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: "TRY", }).format(on_process)
        },
        {
            title: 'Onay',
            dataIndex: 'success',
            align: 'center',
            filterMode: 'tree',
            render: (_,{success}) => new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: "TRY", }).format(success)
        },
        {
            title: 'İptal',
            dataIndex: 'failed',
            align: 'center',
            filterMode: 'tree',
            render: (_,{failed}) => new Intl.NumberFormat('tr-TR',{ style: 'currency', currency: "TRY", }).format(failed)
        }
    ];


    return(<>



        {
            !isMobile &&
            <>

                <Space>
                    <Button
                        type={getDay[0].format("YYYY-MM-DD") === dayjs(new Date()).format("YYYY-MM-DD") ? "primary" : "default"}
                        onClick={() => {
                            setDay([dayjs(new Date()),dayjs(new Date())])
                        }}
                        icon={<CalendarOutlined />}>
                        Bugün
                    </Button>
                    <DatePicker.RangePicker
                        disabledDate={(current) => {
                            return current && current > dayjs().endOf('day');
                        }}

                        onChange={(date, dateString) => {
                            if(dateString[0] === "" || dateString[1] === ""){
                                setDay([dayjs(new Date()),dayjs(new Date())])
                            }else{
                                setDay([date[0],date[1]])
                            }
                        }}
                        defaultValue={getDay}
                        value={getDay}
                        style={{width:350}}
                        showTime={true}
                        format={"DD/MM/YYYY HH:mm"} />

                </Space>

                <Row gutter={20}>

                    <Col span={12}>
                        <Table
                            style={{ width:"100%", marginTop: 15}}
                            size={"small"}
                            columns={columnsCount}
                            dataSource={getRecords}
                            bordered
                            title={() => "Personel Bazlı Toplam İşlemler"}
                            pagination={false}
                        />
                    </Col>
                    <Col span={12}>
                        <Table
                            style={{ width:"100%", marginTop:15}}
                            size={"small"}
                            columns={columnsSum}
                            dataSource={getRecords}
                            bordered
                            title={() => "Personel Bazlı Toplam Tutarlar"}
                            pagination={false}
                        />
                    </Col>

                    {
                        Object.keys(getMerchantTotal?.system ?? [])?.map(item => {

                            return (
                                <Col span={24} style={{marginTop:25}}>
                                    <Card title={`Sistem Toplam / ${item}`} bodyStyle={{padding:2}}>
                                        {
                                             console.log([
                                                 ["Başlık","Tutar", { role: "style" }],
                                                 ["Başarılı",getMerchantTotal?.system[item]?.success ?? 0,"#38ff00"],
                                                 ["Başarısız",getMerchantTotal?.system[item]?.failed ?? 0,"#f20404"],
                                                 ["İşlemde",getMerchantTotal?.system[item]?.on_process ?? 0,"#f27e04"],
                                                 ["Fark",getMerchantTotal?.system[item]?.diff ?? 0,"#0464f2"],
                                             ])
                                        }
                                        <Table
                                            style={{ width:"100%", marginTop:0}}
                                            size={"small"}
                                            columns={columnsSystem}
                                            dataSource={[
                                                {
                                                    title : "Başarılı",
                                                    total : getMerchantTotal?.system[item]?.success ?? 0,
                                                    currency: item
                                                },
                                                {
                                                    title : "Başarısız",
                                                    total : getMerchantTotal?.system[item]?.failed ?? 0,
                                                    currency: item
                                                },
                                                {
                                                    title : "İşlemde",
                                                    total : getMerchantTotal?.system[item]?.on_process ?? 0,
                                                    currency: item
                                                },
                                                {
                                                    title : "Fark",
                                                    total : getMerchantTotal?.system[item]?.diff ?? 0,
                                                    currency: item
                                                }
                                            ]}
                                            bordered={false}
                                            pagination={false}
                                        />
                                    </Card>
                                </Col>
                            )
                        })
                    }


                    <Col span={24}>
                        <Table
                            style={{ width:"100%", marginTop: 15}}
                            size={"small"}
                            columns={columnsBanks}
                            dataSource={getBanksMonitor}
                            bordered
                            title={() => "Bayi & Bankalar & Kasa"}
                            pagination={false}
                        />
                    </Col>

                    <Col span={24}>
                        <Table
                            style={{ width:"100%", marginTop:15}}
                            size={"small"}
                            columns={columnsAddionatal}
                            dataSource={getMerchantTotal?.additional !== undefined ? getMerchantTotal.additional : []}
                            bordered
                            title={() => "Ek Site Toplamları"}
                            pagination={false}
                        />
                    </Col>




                    <Col span={24}>
                        <Table
                            style={{ width:"100%", marginTop:15}}
                            size={"small"}
                            columns={methodAgents}
                            dataSource={getRecords}
                            bordered
                            title={() => "Method Bazlı"}
                            pagination={false}
                        />
                    </Col>
                </Row>

            </>
        }

        {
            isMobile && <div style={{ width:"100%", height:"calc(100vh - 150px)", overflow:"scroll" }}>


                {
                    getRecords.length > 0 && getRecords.map(item => {


                        return(
                            <div style={{marginBottom: "10px", border: "1px solid #c4b8b8", padding: "6px", borderRadius: "6px", paddingTop: "0px", paddingBottom: "0px"}}>
                                {
                                    columnsCount.map(colItem =>
                                        <div style={{width:"100%", display:"flex", margin:"6px 0px", background:"#f3f3f3", borderRadius:6 }}>
                                            <div   style={{padding: "6px 0px", width:130, display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                {colItem["title"]}
                                            </div>
                                            <div   style={{padding: "6px 0px", flex: "auto", display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                {colItem["render"](item,item)}
                                            </div>
                                        </div>)
                                }
                            </div>
                        )
                    })
                }

                {
                    getRecords.length > 0 && getRecords.map(item => {


                        return(
                            <div style={{marginBottom: "10px", border: "1px solid #c4b8b8", padding: "6px", borderRadius: "6px", paddingTop: "0px", paddingBottom: "0px"}}>
                                {
                                    columnsSum.map(colItem =>
                                        <div style={{width:"100%", display:"flex", margin:"6px 0px", background:"#f3f3f3", borderRadius:6 }}>
                                            <div   style={{padding: "6px 0px", width:130, display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                {colItem["title"]}
                                            </div>
                                            <div   style={{padding: "6px 0px", flex: "auto", display:"flex", alignItems:"center", paddingLeft: 10}}>
                                                {colItem["render"](item,item)}
                                            </div>
                                        </div>)
                                }
                            </div>
                        )
                    })
                }


                {
                    getRecords.length === 0 && <>



                        <Result
                            status="warning"
                            title="İşlem Bulunamadı"
                        />

                    </>
                }

            </div>
        }


    </>)

};


export default DailyAgentsFinance;