import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {
    Row,
    Col,
    Card,
    Form,
    DatePicker,
    Button,
    Select,
    Space,
    Badge,
    Tag,
    Table,
    Input,
    App,
    InputNumber,
} from "antd";
import 'dayjs/locale/tr';
import { ReloadOutlined, KeyOutlined,FilterOutlined, UserOutlined, DollarOutlined} from '@ant-design/icons';
import {PasswordInput} from "antd-password-input-strength";
import {ApiPost} from "../../../../../../Supports/ApiManager";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import MarkdownEditor from '@uiw/react-markdown-editor';
import {useRecoilState} from "recoil";
import {TimeZonesStorage} from "../../../../../../Storage/TimeZonesStorage";




const CMSettingsAdmin = (props) => {

    const { message, notification, modal }                      = App.useApp();

    const [MethodAccountSettings]                                        = Form.useForm();
    const [PersonalUpdateForm]                                  = Form.useForm();
    const [level, setLevel]                                     = useState(0);
    const [getContentTransfer, setContentTransfer]                      = useState('');
    const [getTimeZones,setTimeZones]                           = useRecoilState(TimeZonesStorage);


    useEffect(()=>{

        ApiPost(`/card/method/${props.id}/view`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                MethodAccountSettings.setFieldsValue({
                   title: result.title,
                   content: result.content,
                   amount: result.amount,
                   status: result.status.value
                });

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            });

    },[])

    const onFinish = (values) => {

        ApiPost(`/card/method/${props.id}/update`,{
            title: values.title,
            content: values.content,
            amount: values.amount,
            status: values.status
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                message.success(response.message);

                props?.onChange(true);


            }).catch(error =>{
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                props?.onChange(true);
            })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return(<>

        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

            <Card title={"Hesap Ayarları"} extra={  <Button icon={<ReloadOutlined />} type="primary" onClick={() => MethodAccountSettings.submit()} block> Güncelle </Button>}>
                <Form
                    form={MethodAccountSettings}
                    name="MethodAccountSettings"
                    layout={"vertical"}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">

                    <Form.Item
                        hasFeedback
                        label="Görünecek Ad"
                        name="title"
                        rules={[{required: true, message: 'This field is required!',} ]}>
                        <Input prefix={<UserOutlined />} placeholder={"Başık giriniz"} />
                    </Form.Item>

                    <Form.Item
                        valuePropName={"value"}
                        hasFeedback
                        label="Kart No"
                        name="content"
                        rules={[{required: true, message: 'This field is required!',} ]}>
                        <MarkdownEditor height="100px" />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Tutar"
                        name="amount"
                        rules={[{required: true, message: 'This field is required!'}  ]}>
                        <InputNumber prefix={<DollarOutlined />} style={{width:"100%"}} stringMode placeholder={"Tutar giriniz"} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Durum"
                        name="status"
                        rules={[{required: true, message: 'This field is required!'}]}>
                        <Select suffixIcon={<FilterOutlined />} placeholder={"Durum seçiniz"} options={[
                            {
                                key: Math.random().toString(),
                                label:"Aktif",
                                value:"active"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Pasif",
                                value:"passive"
                            }
                        ]} />
                    </Form.Item>

                </Form>
            </Card>

        </div>
    </>)

};


export default CMSettingsAdmin;