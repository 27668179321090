import React, {useEffect, useRef, useState} from "react";
import { AppContainer, NavBar, NavBarLink, NavBarThemeSwitch, NavPageContainer, AppTheme } from "react-windows-ui";
import { useNavigate,Outlet } from "react-router-dom";
import "react-windows-ui/config/app-config.css";
import "react-windows-ui/dist/react-windows-ui.min.css";
import "react-windows-ui/icons/winui-icons.min.css";
import "./Assets/App.css";
import {useRecoilState} from "recoil";
import {ColorStorageState} from "./Storage/ColorStorage";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import * as liveOne from './Assets/lottiefiles/live_1.json'
import * as liveTwo from './Assets/lottiefiles/live_2.json'
import * as liveThree from './Assets/lottiefiles/live_3.json'
import {Button, Dropdown, Avatar, Popover, App, Drawer, Form, Card, QRCode, Space, Result, Image, List, Skeleton, Switch, Input} from "antd";
import {io} from "socket.io-client";
import {SocketLiveMapState} from "./Storage/SocketLiveMap";
import {KeyOutlined, ReloadOutlined, UserOutlined, SkinTwoTone, MehTwoTone} from '@ant-design/icons';
import {SocketMerchantReceiveTransactionUpdate} from "./Storage/SocketMerchantReceiveTransactionUpdate";
import {SocketMerchantReceiveTransactionStatusUpdate} from "./Storage/SocketMerchantReceiveTransactionStatusUpdate";
import {ApiGet, ApiPost, ApiPostPaymentGateway} from "./Supports/ApiManager";
import {AuthAccount} from "./Storage/AuthAccount";
import {PasswordInput} from "antd-password-input-strength";
import Settings from "./Settings";
import {TimeZonesStorage} from "./Storage/TimeZonesStorage";
import {getAnalytics,getMessaging,isSupported,initializeApp,firebaseConfig,getToken,onMessageListener} from "./firebase";
import {SocketLiveTransactionsState} from "./Storage/SocketLiveTransactions";
import { PaperClipOutlined, HomeFilled, AlertFilled, PieChartFilled, SecurityScanOutlined,AimOutlined,HomeOutlined, AreaChartOutlined,PieChartOutlined, BarcodeOutlined, CreditCardOutlined, TeamOutlined,  ConsoleSqlOutlined, FundFilled } from '@ant-design/icons';


const autoGroup = (records) => {

    const minuteGroups = {};

    records.forEach(record => {

        const timestamp = record.time / 1000;
        const minute    = Math.floor(timestamp / 60);

        minuteGroups[minute] = (minuteGroups[minute] || 0) + 1;
    });

    Object.keys(minuteGroups).forEach(key => {
        const total             = minuteGroups[key];
        const timeInSeconds     = key * 60;
        const timeFormatted     = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

        minuteGroups[key] = {
            total: total,
            time: timeFormatted
        };
    })

    const totalMinutes = Object.keys(minuteGroups).length;

    return {
        totalMinutes,
        minuteGroups
    };
};

const timeCounter = (initHours,initMinutes,initSeconds, returnCallback) => {


    var countdownDate = new Date();
    countdownDate.setHours(parseInt(initHours));
    countdownDate.setMinutes(parseInt(initMinutes));
    countdownDate.setSeconds(initSeconds);

    const countdown = () => {
        var nowCurrent = new Date();

        var now = new Date().getTime();
        var distance = countdownDate - now;
        if((nowCurrent.getHours() + ":" + nowCurrent.getMinutes()) === (initHours + ":" + initMinutes)){
            window.localStorage.clear();

            setTimeout(()=>{
                window.location.reload();
            },1000);

        }
        else if (distance <= 0) {
            clearInterval(interval);
            returnCallback("00:00:00");
        } else {
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            returnCallback(hours + ":" + minutes + ":" + seconds);
        }
    }

    var interval = setInterval(countdown, 1000);

}

const AuthScreen = () => {

    const { message }           = App.useApp();
    const [getAccount,setAccount]   = useRecoilState(AuthAccount);
    const [getColor,setColor]   = useRecoilState(ColorStorageState);
    const [,setSocketLiveMap]   = useRecoilState(SocketLiveMapState);
    const [,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);
    const [getStatusReceive,setStatusReceive]   = useRecoilState(SocketMerchantReceiveTransactionStatusUpdate);
    const [GetSocketLiveTransactionsState,SetSocketLiveTransactionsState]   = useRecoilState(SocketLiveTransactionsState);

    const navigate                                      = useNavigate();
    const [route, setRoute]                             = useState("");
    const [getAnimate,setAnimate]                       = useState(liveOne);
    const [getBlinkAction,setBlinkAction]               = useState(false);
    const [getAudio,setAudio]                           = useState(false);
    const getAudioEffect                                = useRef(typeof Audio !== "undefined" ? new Audio("/bip.mp3") : null);
    const [isConnected, setIsConnected]                 = useState(false);
    const [getLiveMonData, setLiveMonData]              = useState([]);
    const [getPasswordDrawer, setPasswordDrawer]        = useState(false);
    const [getDomainDrawer, setDomainDrawer]        = useState(false);
    const [getMyProfile, setMyProfile]                  = useState({});
    const [getMenuItems, setMenuItems]                  = useState([]);
    const [getAuthItem, setAuthItem]                  = useState([]);
    const [getActiveDetect, setActiveDetect]            = useState(false);
    const [getShiftTimer, setShiftTimer]                = useState("00:00:00");
    const [getTimeZones,setTimeZones]                   = useRecoilState(TimeZonesStorage);
    const [getStatusUpdate, setStatusUpdate]            = useState(false);
    const [PersonalUpdateForm]                          = Form.useForm();
    const [level, setLevel]                             = useState(0);
    const [getEmployers, setEmployers]                  = useState([]);
    const [getSocketInit, setSocketInit]                = useState(false);
    const [isOnline, setIsOnline]                       = useState(navigator.onLine);





    const [ReserveDomainUpdateForm]                     = Form.useForm();
    const [getReserveDomain, setReserveDomain]          = useState("");


    useEffect(() => {

        ApiGet(`/system/read/reserveDomain`).then(response => {

            if(response.status !== "success"){
                throw new Error(response.message)
            }

            setReserveDomain(response.result.domain ?? "");
        
        }).catch(error =>{
            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
        });


    }, [isOnline]);

    const onFinishDomainSetting = (values) => {

        ApiPost(`/system/update/reserveDomain`,{
            domain:       values.domain,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                message.success(response.message);

            }).catch(error =>{
            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
        })

    };


    const onFinishFailedDomainSetting = (errorInfo) => {
        console.log('Failed on domain setting:', errorInfo);
    };








    // Update network status
    const handleStatusChange = () => {
        setIsOnline(navigator.onLine);
        if(navigator.onLine){

            if(getSocketInit){
                getSocketInit.connect();
            }
        }else{
            console.log("Network connection failed");
        }
    };


    useEffect(() => {

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);


    useEffect(() => {

        window.addEventListener("focus", handleStatusChange);
        return () => {
            window.removeEventListener("focus", handleStatusChange);
        };
    }, []);


    const _navigate = (route) => {
        navigate(route);
        setRoute(route);
    }


    useEffect(() => {

        const FirebaseAppInit = initializeApp(firebaseConfig);


        if(!["127.0.0.1","hostname"].includes(window.location.hostname)){
            isSupported()
                .then((isSupportFirebase) => {

                    // if(isSupportFirebase){

                    //     getToken(getMessaging(FirebaseAppInit))
                    //         .then(token => {

                    //             ApiPost("/profile/my/notification/update",{
                    //                 token: token
                    //             })
                    //                 .then(async response => {

                    //                     if(response.status !== "success"){
                    //                         throw new Error(response.message);
                    //                     }

                    //                 })
                    //                 .catch(error => {
                    //                     message.error("Bildirim hatası");
                    //                 })

                    //         })
                    //         .then(error => {
                    //             error !== undefined && message.error("Bildirim izni alınamadı");
                    //         })


                    // }

                })
                .catch((errorFirebaseMessaging) => {})
        }


    },[])

    const getAgentsStatus = () => {

        if(["admin","finance","merchant","root"].includes(getAccount?.role?.value)){
            ApiPost("/profile/access/merchant")
                .then(async response => {

                    if(response.status !== "success"){
                        throw new Error(response.message);
                    }


                    setEmployers(response.result);

                })
                .catch(error => {
                    message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                })
        }


    }

    useEffect(getAgentsStatus,[])


    useEffect(()=>{

        let getAutoCheck = setInterval(getAgentsStatus,5000);


        return () => {
            clearInterval(getAutoCheck);
        }

    },[getAccount?.role?.value])


    const getMyProfiles = ()=>{

        ApiPost("/profile/my/profile")
            .then(async response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setMyProfile(response.result);

                localStorage.setItem('role', response?.result?.role?.value ?? null);

                timeCounter(response.result.shift_finish.split(":")[0],response.result.shift_finish.split(":")[1],0,getTimerValue => {
                    setShiftTimer(getTimerValue);
                });

                var tabId = sessionStorage.tabId ? sessionStorage.tabId : sessionStorage.tabId = Math.random();

                if (typeof localStorage.tabId === 'undefined'){
                    localStorage.tabId = tabId;
                }

                if (tabId !== localStorage.tabId){
                    if(response.result.multiple_tab.value === "passive"){
                        setActiveDetect(true);
                    }
                }

                window.onbeforeunload = function(){
                    if (tabId === localStorage.tabId)
                        delete localStorage.tabId;
                };

                setMenuItems([
                    {
                        key: '1',
                        label: 'Profil Ayarları',
                        onClick: () => setPasswordDrawer(true)
                    }
                ]);

                /*

                if(["admin","merchant","root"].includes(response?.result?.role?.value)){
                    setMenuItems(current => [...current,
                        {
                            key: '2',
                            label: 'Yönetim Paneli',
                            onClick: () => navigate("admin")
                        }
                    ]) }else{
                        navigate("error")
                }
                        */

                if(["technic","root"].includes(response?.result?.role?.value)){
                    setMenuItems(current => [...current,
                        {
                            key: '3',
                            label: 'Domain Ayarları',
                            onClick: () => setDomainDrawer("true")
                        }
                    ]);
                }

                setMenuItems(current => [...current,
                    {
                        key: '4',
                        danger: true,
                        label: 'Çıkış Yap!',
                        onClick: () => {
                            message.info("Çıkış Yapılıyor");

                            window.localStorage.clear();

                            setTimeout(()=>{
                                navigate("/auth/login");
                            },1000)
                        }
                    }
                ]);

                setAccount(response.result);


            }).catch(error => {
            console.error(error)
        })


        ApiPostPaymentGateway("/timezones",{  })
            .then(async response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setTimeZones(response.result);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })



    };


    useEffect(getMyProfiles,[])

    useEffect(()=>{
        setSocketLiveMap({
            charts_data: Object.values(autoGroup(getLiveMonData).minuteGroups).map(item => ({key: (Math.random().toString()), label:item.time, value: item.total})),
            data: getLiveMonData
        });
    },[getLiveMonData])

    useEffect(()=>{

        if(window.localStorage.getItem("notification_audio") === null){
            window.localStorage.setItem("notification_audio","passive");
            setAudio(false);
        }

        getAudioEffect.loop     = false;
        getAudioEffect.autoplay = false;

    },[getAudio])

    useEffect(()=>{

        if(["active","passive"].includes(window.localStorage.getItem("notification_audio"))){
            if(window.localStorage.getItem("notification_audio") === "active"){
                setAudio("active");
            }
            if(window.localStorage.getItem("notification_audio") === "passive"){
                setAudio(false);
            }
        }

    },[])

    useEffect(()=>{

        try{
            if(getAudioEffect !== null){
                if(getAudio && getBlinkAction){
                    getAudioEffect.current.currentTime  = 0;
                    getAudioEffect.current.play().catch(error => {
                        console.info("İşlem Güncellemesi, Bildirim sesi almak için sesi açınız yada ekranda mouse işlemi yapınız.",error);
                    });
                }
            }
        }catch (e){
            console.error("Bildirim Sesi Hatası : ",e);
        }
    },[getBlinkAction])

    useEffect(() => {

        const SocketConnectServer = io(Settings.socket_server,{
            query: {
                merchant_access: JSON.stringify(getMyProfile.merchant_access),
                merchant_id: window.localStorage.getItem("merchant_id"),
                token: window.localStorage.getItem("token"),
                sender: "dashboard"
            }
        });

        setSocketInit(SocketConnectServer);

        SocketConnectServer.on('connect', ()=>{

            setIsConnected(true);
            setAnimate(liveTwo);

        });

        SocketConnectServer.on("disconnect", (disconnect) => {
            setInterval(() => {
                SocketConnectServer.connect();
            },1000);

            setAnimate(liveThree);
            setIsConnected(false);
        });

        SocketConnectServer.on("system:payment:list:receiver", (data) => {
            setSocketReceive({
                update: new Date().toTimeString()
            });
            setStatusReceive({
                update: new Date().toTimeString()
            });
        });

        SocketConnectServer.on("system:transaction:room", (data) => {

            SetSocketLiveTransactionsState(data);

        });

        SocketConnectServer.on("merchant:payment:create:receiver", (data) => {

            setLiveMonData(currentData => [...currentData,{
                transaction_id: data.transaction_id,
                merchant_id: data.merchant_id,
                time: new Date().getTime(),
            }]);

            setTimeout(()=> {
                setBlinkAction(false);
            },500);

            setBlinkAction(true);

            setSocketReceive({
                update: new Date().toTimeString()
            });

            setStatusReceive({
                update: new Date().toTimeString()
            });

        });

        SocketConnectServer.on("connect_error", () => {

            setInterval(() => {
                SocketConnectServer.connect();
            },1000);

            setAnimate(liveThree);
            setIsConnected(false);


        });

        return () => {

            SocketConnectServer.offAny()
            SocketConnectServer.removeAllListeners();
            SocketConnectServer.disconnect();


        }

    }, [getMyProfile]);








    const onFinishPassword = (values) => {

        ApiPost(`/profile/my/password/change`,{
            password:       values.password,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                message.success(response.message);
                message.destroy();
                message.info("Çıkış Yapılıyor");

                window.localStorage.clear();

                setTimeout(()=>{
                    navigate("/auth/login");
                },1000)


            }).catch(error =>{
            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
        })

    };


    const set2FA = () => {

        ApiPost(`/profile/my/2fa/change`)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }


                getMyProfiles();


            }).catch(error =>{
            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
        })

    };


    const onFinishFailedPassword = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if(!getActiveDetect){
        return (
            <AppContainer>

                <AppTheme
                    scheme={getColor}
                    color={"#000000"}
                    colorDarkMode={"#000000"}
                    onColorChange={() => {}}
                    onSchemeChange={() => {}}
                />

                <NavBar
                    collapsed={false}
                    shadowOnScroll={true}
                    theme="dark"
                    titleBarMobile={<div style={{flex:1, alignItems:"center", display:"flex", }}>
                        <img src="https://uzbepay.com/assets/uzbe.png" height={30} />
                        <div style={{flex:1, alignItems:"center", display:"flex", justifyContent:"flex-end"}}>
                            <div style={{width: 50, position:"relative", height: "50px", display: "flex", alignItems: "center", placeContent: "center",    borderRadius: "6px"}}>
                                <Button shape={"circle"} icon={<i className={getAudio ? "icons10-bell" : "icons10-bell-disabled"}/> } onClick={() => {
                                    const setStatusAudio = !getAudio;
                                    setAudio(setStatusAudio);
                                    window.localStorage.setItem("notification_audio",setStatusAudio ? "active" : "pasive")
                                }}/>
                            </div>
                            <Popover placement="bottom" title={"Sunucu"} content={isConnected ? "Soket bağlantısı başarılı" : "Soket Bağlantı Hatası"}>
                                <div style={{width: 50, position:"relative", height: "50px", display: "flex", alignItems: "center", placeContent: "center" }}>
                                    <Player
                                        autoplay
                                        loop
                                        src={getAnimate}
                                        style={{ height: 35, width: 35 }}>
                                    </Player>
                                    { getBlinkAction && <div style={{position:"absolute", width:6, height:6, borderRadius:100, background:"green", bottom:5}} /> }
                                </div>
                            </Popover>
                        </div>
                    </div>}>
                    
                    <div style={{flex:1, alignItems:"center", padding:"20px", display:"flex", background:"#fff", justifyContent:"center" }}>
                    <img src="https://uzbepay.com/assets/uzbe.png" height={40} />
                    </div>

                    <div className={"menuCustomV1"}>

                        <div style={{flex:1}}>
                            <Dropdown
                                trigger={["click"]}
                                placement="bottomLeft"
                                arrow={{ pointAtCenter: true }}
                                menu={{
                                    items:getMenuItems,
                                }}>
                                <div className="privateZoneMenu">
                                    <div className={"coverImg"}>
                                        <Avatar icon={<UserOutlined style={{fontSize:14}} />} size={40} style={{display:"flex", justifyContent:"center", alignContent:"center", background:"var(--secondAvatarColor)"}}/>
                                    </div>
                                    <div className="hideInside">
                                        <strong style={{fontSize:14, marginTop:0}}>{getMyProfile?.name} {getMyProfile?.surname}</strong>
                                        <span style={{fontSize:10, color:"white", fontWeight:"200", fontFamily:"uzbe-light"}}>{getMyProfile?.role?.label}</span>
                                    </div>
                                </div>
                            </Dropdown>
                            <div className="app-hr"/>

                            <NavBarLink
                                key="dashboard"
                                text="Gösterge Paneli"
                                active={window.location.pathname === "/app"}
                                onClick={() => _navigate("/app")}
                                icon={<HomeOutlined />}
                            />

                            {
                                ["admin","root"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="sidemanager"
                                    text="Ödeme İşlemleri"
                                    active={window.location.pathname === "/app/payments"}
                                    onClick={() => _navigate("payments")}
                                    icon={<AimOutlined />}
                                />
                            }
                            
                            <span style={{fontSize:12, color:"#ffffff66", fontWeight:"500", padding:"5px 30px", lineHeight:"40px"}}>İSTATİSTİKLER</span>
                            <div className="app-hr"/>
                            {
                                ["admin","root","operator"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="dailyTransaction"
                                    text="Günlük İşlemler"
                                    active={window.location.pathname === "/app/daily"}
                                    onClick={() => _navigate("daily")}
                                    icon={<PieChartOutlined />}
                                />
                            }
                            {
                                ["root","operator"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="systemReport"
                                    text="Raporlamalar"
                                    active={window.location.pathname === "/app/reports"}
                                    onClick={() => _navigate("reports")}
                                    icon={<AreaChartOutlined />}
                                />
                            }
                            {
                                ["root"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="cashReport"
                                    text="Kasa Raporları"
                                    active={window.location.pathname === "/app/cash"}
                                    onClick={() => _navigate("cash")}
                                    icon={<BarcodeOutlined />}
                                />
                            }
                            <span style={{fontSize:12, color:"#ffffff66", fontWeight:"500", padding:"5px 30px", lineHeight:"40px"}}>SİSTEM AYARLARI</span>
                            <div className="app-hr"/>
                            {
                                ["admin","root"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="accountManager"
                                    text="Hesap Ayarları"
                                    active={window.location.pathname === "/app/accounts"}
                                    onClick={() => _navigate("accounts")}
                                    icon={<CreditCardOutlined />}
                                />
                            }
                            {
                                ["admin","root","operator"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="callbackHistory"
                                    text="Callback Geçmişi"
                                    active={window.location.pathname === "/app/callbacks"}
                                    onClick={() => _navigate("callbacks")}
                                    icon={<ConsoleSqlOutlined />}
                                />
                            }
                            {
                                ["root"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="personalManager"
                                    text="Personel Yönetimi"
                                    active={window.location.pathname === "/app/personals"}
                                    onClick={() => _navigate("personals")}
                                    icon={<TeamOutlined />}
                                />
                            }
                            {
                                ["root"].includes(getAccount?.role?.value) &&
                                <NavBarLink
                                    key="merchantManager"
                                    text="Bayi Yönetimi"
                                    active={window.location.pathname === "/app/merchants"}
                                    onClick={() => _navigate("merchants")}
                                    icon={<SecurityScanOutlined />}
                                />
                            }

                        <span style={{fontSize:12, color:"#ffffff66", fontWeight:"500", padding:"5px 30px", lineHeight:"40px"}}>ARAYÜZ SERVİSİ</span>
                        <div className="app-hr"/>

                        <div style={{margin:"8px 0",  display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <Popover placement="right" title={"Sunucu"} content={isConnected ? "Socket bağlantısı başarılı!" : "Socket bağlanamadı!"}>
                               <div style={{width: "80%", position:"relative", height: "35px", display: "flex", alignItems: "center", gap:"10px", placeContent: "center", border:"1px solid #ffffff3d", color:"#ffffff6b", margin: "15px auto", borderRadius: "100px"}}>
                               Sistem Durumu : 
                                    <Player
                                        autoplay
                                        loop
                                        src={getAnimate}
                                        style={{ height: 45, width: 45 }}>
                                    </Player>
                                    { getBlinkAction && <div style={{position:"absolute", width:6, height:6, borderRadius:100, background:"green", bottom:5}} /> }
                                </div>
                            </Popover>
                            <div style={{width: "80%", position:"relative", height: "30px", display: "flex", alignItems: "center", placeContent: "center",  margin: "0 auto", borderRadius: "25px", marginBottom:10}}>
                                <Button shape={"round"} style={{width:"100%", border:"none", background:!getAudio ? "red" : "#11f111"}} icon={<i style={{color: (!getAudio ? "white" : "white")}} className={getAudio ? "icons10-bell" : "icons10-bell-disabled"}/> } onClick={() => {
                                    const setStatusAudio = !getAudio;
                                    setAudio(setStatusAudio);
                                    window.localStorage.setItem("notification_audio",setStatusAudio ? "active" : "pasive");
                                    setStatusAudio ? message.success("Bildirim sesi açıldı.") : message.error("Bildirim sesi kapatıldı.");
                                }}/>
                            </div>
                        </div>

                        </div>



                    </div>
                </NavBar>
                <NavPageContainer >
                    <Outlet/>
                </NavPageContainer>

                <Drawer
                    title="Profil Ayarları"
                    width={450}
                    placement="right"
                    onClose={() => setPasswordDrawer(false)}
                    open={getPasswordDrawer} >

                    <Card
                        title={"Şifre Değiştir"}
                        extra={<Button icon={<ReloadOutlined />} type="primary" onClick={()=>PersonalUpdateForm.submit()}  block> Güncelle </Button>}>

                        <Form
                            form={PersonalUpdateForm}
                            name="PersonalUpdateForm"
                            layout={"vertical"}
                            onFinish={onFinishPassword}
                            onFinishFailed={onFinishFailedPassword}
                            autoComplete="off">

                            <Form.Item
                                hasFeedback
                                label="Yeni Şifre"
                                name="password"
                                rules={[{required: true, message: 'Bu alan zorunludur!'},
                                    {
                                        validator: async () => {
                                            return level >= 1 ? Promise.resolve() : Promise.reject("Şifre güvenliği çok düşük!");
                                        },
                                        message: "Güçlü bir şifre giriniz."
                                    }]}>
                                <PasswordInput prefix={<KeyOutlined />} onLevelChange={setLevel} />
                            </Form.Item>

                        </Form>
                    </Card>

                    <Card
                        bodyStyle={{display:"flex", justifyContent:"center", alignContent:"center"}}
                        style={{marginTop:25}}
                        title={"Google Authenticator"}
                        extra={
                            <>
                                <Button
                                    icon={<ReloadOutlined />}
                                    type="primary"
                                    onClick={set2FA}
                                    danger={getMyProfile["2fa_status"] !== undefined && getMyProfile["2fa_status"]["value"] === "active"}
                                    block>
                                    {getMyProfile["2fa_status"] !== undefined && <>
                                        {getMyProfile["2fa_status"]["value"] === "active" ? "Kapat" : "Aktif Et"}
                                    </>}
                                </Button>
                            </>}>

                        {getMyProfile["2fa_status"] !== undefined && <>
                            {getMyProfile["2fa_status"]["value"] === "active" && <>
                                <Image
                                    preview={false}
                                    width={150}
                                    src={getMyProfile["2fa_key"]["image"]}/>
                            </>}

                            {getMyProfile["2fa_status"]["value"] === "passive" && <QRCode value={":)"} status="scanned" size={150} />}
                        </>}

                    </Card>

                </Drawer>
                <Drawer
                    title="Domain Ayarları"
                    width={450}
                    placement="right"
                    onClose={() => setDomainDrawer(false)}
                    open={getDomainDrawer} >

                    <Card
                        title={"Domain Değiştir"}
                        extra={<Button icon={<ReloadOutlined />} type="primary" onClick={()=>ReserveDomainUpdateForm.submit()}  block> Güncelle </Button>}>

                        <Form
                            form={ReserveDomainUpdateForm}
                            name="ReserveDomainUpdateForm"
                            layout={"vertical"}
                            onFinish={onFinishDomainSetting}
                            onFinishFailed={onFinishFailedDomainSetting}
                            autoComplete="off">

                            <Form.Item
                                hasFeedback
                                label="Aktif Domain"
                                name="domain"
                                type="url"
                                rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                                <Input value={getReserveDomain} defaultValue={getReserveDomain} prefix={<PaperClipOutlined />} placeholder={"Buraya domain yazınız.."} />
                            </Form.Item>

                        </Form>
                    </Card>

                </Drawer>
            </AppContainer>
        )
    }else{
        return (
            <AppContainer>
                <AppTheme
                    scheme={getColor}
                    color={"#000000"}
                    colorDarkMode={"#000000"}
                    onColorChange={() => {}}
                    onSchemeChange={() => {}}
                />

                <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>

                    <div style={{width:"100%", height:"auto"}}>
                        <Result
                            status="500"
                            title="İŞLEMLERİ TEK PENCERE ÜZERİNDEN YAPINIZ"
                        />
                    </div>

                </div>


            </AppContainer>
        )
    }
}



export default AuthScreen;
