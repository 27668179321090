import React, {useEffect, useRef, useState} from "react";
import {Button, Drawer, Tabs, Card, Row, Col} from "antd";
import WaitingRoom from "./Payment/WaitingRoom";
import OnProcessRoom from "./Payment/OnProcessRoom";
import {useRecoilState} from "recoil";
import {AuthAccount} from "../../Storage/AuthAccount";
import ErrorPageRouter from "../Respose/ErrorPageRouter";
import {SocketMerchantReceiveTransactionUpdate} from "../../Storage/SocketMerchantReceiveTransactionUpdate";
import DailyFinance from "./Finance/DailyFinance";
import DailyAgentsFinance from "./Finance/DailyAgentsFinance";
import SystemReportFinance from "./Finance/SystemReportFinance";

const startAt = new Date();
startAt.setHours(0);
startAt.setMinutes(0);

const finishAt = new Date();
finishAt.setHours(23);
finishAt.setMinutes(59);



let items = [
    {
        key: '1',
        label: 'Kasa Raporları',
        children: <DailyAgentsFinance/>,
    }
];


const CashReportScreen = () => {

    const [getStatic,setStatic]                 = useState(null);
    const [getHistoryDrawer,setHistoryDrawer]   = useState(false);
    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);
    const [getAccount,]             = useRecoilState(AuthAccount);


    const role = localStorage.getItem('role') ?? null;
    if(["admin","finance","merchant","root","operator"].includes(role) === false) {
        items = [];
    } else {
        if(role === "operator") {
            items.splice(0, 1)
        }
    }
    if(["root"].includes(getAccount?.role?.value)){
    return(
        <div style={{padding:0}}>
        <Card className="customCard" style={{borderRadius:"0", borderBottom:"none"}}>
        <Row gutter={0}>
        <Col xs={24} sm={24} md={24}  lg={24} xl={24} xxl={24} style={{padding:10}}>
            <Card title={"Kasa Raporları"}>
            <DailyAgentsFinance/>
            </Card>
        </Col>
         </Row>
         </Card>
        </div>
    )}else{
        return(
            <div style={{padding:15, paddingTop:5}}>
                <ErrorPageRouter/>
            </div>
        )
    }

};


export default CashReportScreen;